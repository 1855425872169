import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ExchangeCountriesCodes } from '@const';

@NgModule()
export class IconsModule {
  /**
   * Constructor
   */
  constructor(
    private _domSanitizer: DomSanitizer,
    private _matIconRegistry: MatIconRegistry,
  ) {
    this._matIconRegistry.addSvgIcon(
      'correlation',

      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/correlation.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'heatmap',

      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/heatmap.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      `${ExchangeCountriesCodes.US}_exchange_icon`,
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/exchanges/USA-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      `${ExchangeCountriesCodes.CC}_exchange_icon`,
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/exchanges/cryptocurrency-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      `${ExchangeCountriesCodes.CA}_exchange_icon`,
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/exchanges/Canada-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'chip_close',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/mat-chip-close-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'reset',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/reset-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'close-modal',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/close-modal.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'close-modal-p',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/Close-p.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'close-icon-narrow',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/close-icon-narrow.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'hint_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/question-mark.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'watch',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/watch.svg'),
    );

    this._matIconRegistry.addSvgIcon(
      'plus_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/plus.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'search-tool_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/search.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'arrow-down_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/arrow-down.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'arrow-left_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/arrow-left.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'plus',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/plus-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'plus_large',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/large_plus.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'select_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/select-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'edit_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/edit-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'trash_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/trash-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'statistic_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/statistic.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'archive_icon_out',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/archive-out.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'archive_icon_in',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/archive-in.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'dots_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/dots.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'move_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/move.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'remove_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/delete.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'attention_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/attention_icon.svg'),
    );

    this._matIconRegistry.addSvgIcon(
      'print',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/print-light.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'print-grey',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/print-grey.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'warning',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/warning-sign.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'news-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/news-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'star-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/star.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'dashes-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/dashes.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'calendar-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/calendar-today.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'calendar-small-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/calendar-small-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'arrow-nav-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/arrow.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'community',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/community.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'help',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/help-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'tutorials',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/tutorials.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'arrow-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/arrow-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'arrow-sm-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/arrow-sm.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'success',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/alert-success.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'tabs-mode-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/tabs-mode-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'split-mode-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/split-mode-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'menu',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/menu-icon/menu.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'close-menu',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/BlueClose.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'close',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/close.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'header-settings-v2',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/newImg/header-setting.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'config-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/menu-icon/config.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'settings-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/menu-icon/Setting.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'logout-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/menu-icon/power.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'arrow',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/arrow-down.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'flag-us',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/countries/flag.svg'),
    ); // remove it later
    this._matIconRegistry.addSvgIcon(
      'time-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/time.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'sync-ok',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/sync-ok.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'sync-in-progress',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/sync-in-progress.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'sync-error',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/sync-error.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'success-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/success-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'danger-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/danger-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'search-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/search-tool.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'alert-error',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/alert-danger.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'alert-success',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/Success.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'filter-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/filter-settings.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'filters-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/filters.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'refresh-update-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/refresh-update-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'risk-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/risk-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'analyze-earning-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/analyze-earning-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'long-arrow-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/long-arrow.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'prev-arrow-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/prev-arrow.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'next-arrow-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/next-arrow.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'drag-handler',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/drag-handler-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'drag-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/drag-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'warning-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/warning.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'close-thick',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/delete-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'lock-opened',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/lock-opened.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'lock-closed',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/lock-closed.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'arrow-right',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/arrow-right.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'eye',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/eye.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'close-eye',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/close-eye.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'show-on-chart',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/show-on-chart.svg'),
    );

    this._matIconRegistry.addSvgIcon(
      'gray-smile',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/GreySmile-current-color.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'red-smile',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/RedSmile.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'yellow-smile',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/YellowSmile.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'green-smile',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/GreenSmile.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'never-smile',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/NeverSmile-current-color.svg'),
    );

    this._matIconRegistry.addSvgIcon(
      'emotions-none-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/smiley-icons/emotions-none-v2.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'emotions-maybe-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/smiley-icons/emotions-maybe-v2.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'emotions-yes-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/smiley-icons/emotions-yes-v2.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'emotions-no-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/smiley-icons/emotions-no-v2.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'emotions-never-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/smiley-icons/emotions-never-v2.svg'),
    );

    this._matIconRegistry.addSvgIcon(
      'pre-market',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/pre-market.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'post-market',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/post-market.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'person',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/person.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'play-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/play.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'info-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/info-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'magic-ball-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/magic-ball-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'magic-ball-top-menu-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/magic-ball-top-menu-icon.svg'),
    );

    // Navigation and menu
    this._matIconRegistry.addSvgIcon(
      'pie_chart_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/menu-icon/pie_chart.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'bubble_chart_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/menu-icon/bubble_chart.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'my_settings_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/menu-icon/my_settings.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'info_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/menu-icon/info.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'calendar_today_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/menu-icon/calendar_today.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'trading_panel_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/menu-icon/trading-panel.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'hot_list_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/menu-icon/hot-list.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'short-selling-stocks-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/menu-icon/short-selling-stocks.svg'),
    );
    // tslint:disable-next-line:max-line-length
    this._matIconRegistry.addSvgIcon(
      'wb_sunny_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/menu-icon/wb_sunny.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'table_chart_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/menu-icon/table_chart.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'economic-calendar',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/economic-calendar.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'earnings-calendar',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/earnings-calendar.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'dividends-calendar',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/dividends-calendar.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'star-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/menu-icon/star-icon.svg'),
    );
    // tslint:disable-next-line:max-line-length
    this._matIconRegistry.addSvgIcon(
      'theme_toggle_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/menu-icon/theme_toggle.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'logout_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/menu-icon/login.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'side_nav_close',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/side-nav-close.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'drop_down_arrow',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/drop-down-arrow.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'alert-disabled',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/disable.svg'),
    );

    this._matIconRegistry.addSvgIcon(
      'hint_filled_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/help_filled.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'chat-gpt',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/chat-gpt.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'cody-chat',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/cody-chat-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'like',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/like.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'dislike',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/dislike.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'limit-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/limit-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'reply-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/reply-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'send-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/send-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'regenerate-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/regenerate-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'pagination-arrow-left-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/pagination-arrow-left.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'pagination-arrow-right-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/pagination-arrow-right.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'pin-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/pin.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'reset-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/reset-narrow.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'tree-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/tree-view.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'list-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/list-view.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'help-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/help.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'table-view-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/table.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'graph-view-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/graph.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'sort_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/sort.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'refresh-small',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/refresh-small.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'breadcrumbs-icon-all',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/settings.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'complete-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/complete-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'outer-link',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/outer-link.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'columns_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/columns.svg'),
    );
    // tslint:disable-next-line:max-line-length
    this._matIconRegistry.addSvgIcon(
      'export-table',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/export-table.svg'),
    );

    this._matIconRegistry.addSvgIcon(
      'rows-view-mode',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/rows-view-mode.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'columns-view-mode',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/columns-view-mode.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'contact_support',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/contact_support.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'info',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/info.svg'),
    );
    // tslint:disable-next-line:max-line-length
    this._matIconRegistry.addSvgIcon(
      'arrow_upward',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/arrow_upward.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'arrow_downward',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/arrow_downward.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'inspiration-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/inspiration.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'prev-slide-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/prev-slide-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'next-slide-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/next-slide-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'import-trades-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/import-trades.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'uploading-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/uploading-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'complete-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/complete-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'save_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/icon_save.svg'),
    );

    this._matIconRegistry.addSvgIcon(
      'add-section',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/add-section.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'break-even-warning-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/break-even-warning-sign.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'yt_logo',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/youtube_icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'rocky-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/rocky-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'data-window-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/data-window-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'heatmap-share-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/heatmap-share-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'help-hint',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/help-current-color-hint.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'home-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/home-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'live-stream-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/live-stream.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'background-video-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/background-window-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'separate-video-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/separate-window-icon.svg'),
    );
    // tslint:disable-next-line:max-line-length
    this._matIconRegistry.addSvgIcon(
      'cards_view_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/cards-view.svg'),
    );
    // tslint:disable-next-line:max-line-length
    this._matIconRegistry.addSvgIcon(
      'list_view_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/list-view-2.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'expand-video-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/expand_view.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'double-arrow-up-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/double-arrow-up.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'double-arrow-down-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/double-arrow-down.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'icon-line-through',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/icon-line-through.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'star-outline-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/star-icon-outlined.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'sun-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/sun-icon.svg'),
    );

    this._matIconRegistry.addSvgIcon(
      'trend-alert',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/trend_alert.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'trend-up',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/trend_up.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'trend-down',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/trend_down.svg'),
    );

    this._matIconRegistry.addSvgIcon(
      'insert_row_above',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/mm-trades/insert-row-above.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'insert_row_below',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/mm-trades/insert-row-below.svg'),
    );

    this._matIconRegistry.addSvgIcon(
      'video_hub_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/video-hub-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'video_hub_replay_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/video-hub-replay-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'chart_avg_icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/chart_avg_icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'undo-arrow-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/undo-arrow-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'redo-arrow-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/redo-arrow-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'tl-quotes-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/tl-quotes-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'notes-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/notes-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'power-x-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/power-x-icon-v2.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'wheel-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/wheel-icon-v2.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'bcs-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/bcs-icon-v2.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'wtf-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/wtf-icon-v2.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'sol-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/sol-icon-v2.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'system-one-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/system-one-icon-v2.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'dividends-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/dividends-icon-v2.svg'),
    );

    this._matIconRegistry.addSvgIcon(
      'rocky-chat-btn',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/rocky.svg'),
    );

    this._matIconRegistry.addSvgIcon(
      'arrow-up-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/arrow-up-icon.svg'),
    );

    ///

    this._matIconRegistry.addSvgIcon(
      'notifications-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/notifications-icon.svg'),
    );

    /// social media icons
    this._matIconRegistry.addSvgIcon(
      'apple-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/social-media-icons/apple-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'facebook-messenger-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/img/Icons/social-media-icons/facebook-messenger-icon.svg',
      ),
    );
    this._matIconRegistry.addSvgIcon(
      'google-business-messages-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/img/Icons/social-media-icons/google-business-messages-icon.svg',
      ),
    );
    this._matIconRegistry.addSvgIcon(
      'instagram-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/social-media-icons/instagram-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'line-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/social-media-icons/line-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'rcs-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/social-media-icons/rcs-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'telegram-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/social-media-icons/telegram-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'viber-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/social-media-icons/viber-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'whatsapp-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/social-media-icons/whatsapp-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'round-sms-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/social-media-icons/round-sms-icon.svg'),
    );
    /// end

    this._matIconRegistry.addSvgIcon(
      'green-checkmark-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/green-checkmark-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'not-verified-phone-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/not-verified-phone-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'verified-phone-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/verified-phone-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'remove-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/remove-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'pxo-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/pxo-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'copy-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/copy-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'dividends',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/dividends.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'menu-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/menu-icon/menu.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'lock-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/editions-lock-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'open-in-new-tab-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/open-in-new-window.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'move-icon',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/Icons/move-icon.svg'),
    );
    this._matIconRegistry.addSvgIcon(
      'refresh-data',
      this._domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/refresh-background-light.svg'),
    );
  }
}
