<div class="trade-length card">
  <div
    [style.width]="longestTradeProgressBarWidth"
    *ngIf="getPropertyValue('avg_trade_days') !== null && getPropertyValue('longest_trade_days') !== null; else noData"
    class="date-progress-metric"
  >
    <div class="date-value">
      <p class="font-12">
        {{ getPastPerformanceMetric('avg_trade_days', 0) }}
      </p>
    </div>
    <div class="progress-wrapper">
      <div
        [style.width]="averageTradeProgressBarWidth"
        class="pogress-value"
      ></div>
    </div>
    <div class="">
      <p class="font-12">
        {{ getPastPerformanceMetric('longest_trade_days', 0) }}
      </p>
    </div>
  </div>
</div>

<ng-template #noData>
  <div class="no-data">-</div>
</ng-template>
