@if (currentSymbol$ | async) {
  <app-doubled-chart
    [defaultChartType]="defaultChartType"
    [afterSavedDataRestored]="afterSavedDataRestored.bind(this)"
    [chartSavedDataKey]="chartSavedDataKey"
    [chartSavedDataKeyPrefix]="'trading'"
    [isDeveloperAccount]="isDeveloperAccount"
    [getChartShapeIdsToExclude]="getChartShapeIdsToExclude"
    [getCustomIndicators]="getCustomIndicators"
    [getTimescaleMarks]="getTimescaleMarks"
    [loadChartData]="loadChartData"
    [symbol$]="currentSymbol$"
    [tvChartSettings]="tvChartSettings"
    [disabledIndicators]="disabledIndicators"
    [resetChart$]="resetChart$"
    [widgetOptionsOverridesFor1DayChart]="widgetOptionsOverridesFor1DayChart"
    [widgetOptionsOverridesFor5MinChart]="widgetOptionsOverridesFor5MinChart"
    (chartReady)="onChartReady($event)"
    (symbolChanged)="onSymbolChanged($event)"
    class="app-tv-chart-container"
  ></app-doubled-chart>
}
