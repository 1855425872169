import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { GroupedWatchlistComponent } from '@c/grouped-watchlist/grouped-watchlist.component';
import { Positions, UserSettings, WatchlistType } from '@const';
import { IWatchlistTableData } from '@core/types';
import { SmileyListType } from '@mod/symbol-smiley/symbol-smiley.model';
import { DialogsService } from '@s/common';
import { ObservableService } from '@s/observable.service';
import { SymbolsService } from '@s/symbols.service';
import { ITradingStrategy, TradingStrategiesService } from '@s/trading-strategies.service';
import { UserDataService } from '@s/user-data.service';
import { IWatchlistData, WatchlistDataService } from '@s/watchlist-data.service';
import { WatchlistService } from '@s/watchlist.service';

@Component({
  selector: 'app-grouped-watchlist-wrapper',
  templateUrl: './grouped-watchlist-wrapper.component.html',
  styleUrls: ['./grouped-watchlist-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class GroupedWatchlistWrapperComponent implements OnInit, OnDestroy {
  @Input() isActive = false;

  @ViewChild('groupedWatchlist') groupedWatchlist: GroupedWatchlistComponent;

  protected readonly smileyListTypes = SmileyListType;
  protected readonly watchlistTypes = WatchlistType;

  protected watchlistTableItems: IWatchlistTableData[] | null = null;

  protected symbol: number | null = null;
  protected firstTradingStrategy: ITradingStrategy | null = null;
  protected secondTradingStrategy: ITradingStrategy | null = null;

  private subscriptions = new Subscription();

  constructor(
    private dialog: MatDialog,
    private dialogsService: DialogsService,
    private watchlistDataService: WatchlistDataService,
    private observableService: ObservableService,
    private tradingStrategiesService: TradingStrategiesService,
    private symbolsService: SymbolsService,
    private userDataService: UserDataService,
    private changeDetectorRef: ChangeDetectorRef,
    private watchlistService: WatchlistService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.symbol = this.observableService.symbol.getValue();
    this.firstTradingStrategy = await this.tradingStrategiesService.getById(
      this.observableService.firstTradingStrategyId.getValue(),
    );
    this.secondTradingStrategy = await this.tradingStrategiesService.getById(
      this.observableService.secondTradingStrategyId.getValue(),
    );

    this.subscriptions.add(
      this.observableService.symbol.subscribe((symbol) => {
        this.symbol = symbol;
      }),
    );

    this.subscriptions.add(
      this.observableService.firstTradingStrategyId.subscribe(async (firstTradingStrategyId) => {
        this.firstTradingStrategy = await this.tradingStrategiesService.getById(firstTradingStrategyId);
        await this.onStrategiesChange(false);
      }),
    );
    this.subscriptions.add(
      this.watchlistService.updateWatchlistSignal$.subscribe(async () => {
        await this.onStrategiesChange(true);
        this.changeDetectorRef.markForCheck();
      }),
    );

    this.subscriptions.add(
      this.observableService.secondTradingStrategyId.subscribe(async (secondTradingStrategyId) => {
        this.secondTradingStrategy = await this.tradingStrategiesService.getById(secondTradingStrategyId);
        await this.onStrategiesChange(false);
      }),
    );

    this.subscriptions.add(this.observableService.watchlistUpdated.subscribe(() => this.onStrategiesChange(true)));

    await this.onStrategiesChange(true);
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public removeAllGroups(): void {
    this.groupedWatchlist.removeAllGroups();
  }

  public addNewGroup(): void {
    this.groupedWatchlist.addNewGroup();
  }

  protected async setCurrentSymbol(event: IWatchlistTableData): Promise<void> {
    const nextSymbol = await this.symbolsService.getById(event.security_id);

    if (!nextSymbol) {
      const isDialogOpen = this.dialog.openDialogs.length;

      if (!isDialogOpen) {
        await this.dialogsService.showNoDataForSymbolMessage(event.symbol);
      }

      return;
    }

    await this.userDataService.set(UserSettings.Symbol, nextSymbol.security_id);
    this.changeDetectorRef.markForCheck();
  }

  protected async addSymbolToWatchlist(): Promise<void> {
    await this.onStrategiesChange(true);
    this.changeDetectorRef.markForCheck();
  }

  protected async removeSymbolFromWatchlist(): Promise<void> {
    await this.onStrategiesChange(true);
    this.changeDetectorRef.markForCheck();
  }

  private async onStrategiesChange(ignoreCache: boolean): Promise<void> {
    const result = await this.watchlistDataService.get(WatchlistType.PowerX, ignoreCache);
    const securityIDs: Record<number, IWatchlistData[]> = {};

    for (const res of result) {
      if (!securityIDs[res.security_id]) {
        securityIDs[res.security_id] = [];
      }

      securityIDs[res.security_id].push(res);
    }

    this.watchlistTableItems = Object.values(securityIDs)
      .map((signals) => {
        const leftTradePosition = this.getSignalText(
          signals.find((s) => s.strategy_id === this.firstTradingStrategy?.id),
        );
        const rightTradePosition = this.getSignalText(
          signals.find((s) => s.strategy_id === this.secondTradingStrategy?.id),
        );

        return {
          id: signals[0].id,
          left_trade_position: leftTradePosition,
          right_trade_position: rightTradePosition,
          symbol: signals[0].symbol,
          company_name: signals[0].company_name,
          security_id: signals[0].security_id,
          created_date: signals[0].created_date,
          country_code: signals[0].country_code,
        };
      })
      .sort((e1, e2) => (e1.symbol > e2.symbol ? 1 : -1));

    this.changeDetectorRef.markForCheck();
  }

  private getSignalText(data: IWatchlistData): string {
    switch (data?.position) {
      case Positions.Short:
        return 'SHORT';
      case Positions.Long:
        return 'LONG';
      default:
        return 'NONE';
    }
  }
}
