<app-wheel-scanner-filter [listOfExpiration]="uniqueListOfExpiration"></app-wheel-scanner-filter>

<div class="cons-agg">
  <mat-tab-group
    [selectedIndex]="getTabIndex(selectedTab)"
    (selectedTabChange)="onTabChanged($event)"
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    class="ws-tabbing scanner-watchlist"
    animationDuration="200"
    disableRipple
  >
    <!--  commented code, to return it later, in case of return also check "tabs" field -->
    <!--    <mat-tab #allTab>
      <ng-template mat-tab-label>
        All
        <span class="scanner-tab-items-number">
          {{ scannerDataAll.length }}
        </span>
      </ng-template>
      <div class="conservative-table">
        <app-scanner-symbols-list
          *ngIf="scannerDataAll.length > 0; else noData"
          [saveSortStateKey]="userSettings.WheelScannerSortAll"
          [isActive]="allTab.isActive"
          [data]="scannerDataAll"
          [smileyListType]="smileyListTypes.Wheel"
          [userSettingsSymbolKey]="userSettings.WheelSymbol"
          (symbolSelected)="onSetCurrentSymbol($event, 'all')"
          (triggerSmiley)="onChangeSmiley()"
        ></app-scanner-symbols-list>
      </div>
    </mat-tab> -->

    <mat-tab #conservativeTab>
      <ng-template mat-tab-label>
        Conservative
        <span class="scanner-tab-items-number">
          {{ scannerDataConservative.length }}
        </span>
      </ng-template>
      <div class="conservative-table">
        <app-scanner-symbols-list
          [saveSortStateKey]="userSettings.WheelScannerSortConservative"
          [isActive]="conservativeTab.isActive"
          [data]="scannerDataConservative"
          [smileyListType]="smileyListTypes.Wheel"
          [userSettingsSymbolKey]="userSettings.WheelSymbol"
          [currentSymbol]="currentSymbolForLists$ | async"
          (symbolSelected)="onSetCurrentSymbol($event, 'conservative')"
          (triggerSmiley)="onChangeSmiley($event)"
          *ngIf="scannerDataConservative.length > 0; else noData"
        ></app-scanner-symbols-list>
      </div>
    </mat-tab>

    <mat-tab #aggressiveTab>
      <ng-template mat-tab-label>
        Aggressive
        <span class="scanner-tab-items-number">
          {{ scannerDataAggressive.length }}
        </span>
      </ng-template>
      <div class="aggresive-table">
        <app-scanner-symbols-list
          [saveSortStateKey]="userSettings.WheelScannerSortAggressive"
          [isActive]="aggressiveTab.isActive"
          [data]="scannerDataAggressive"
          [smileyListType]="smileyListTypes.Wheel"
          [userSettingsSymbolKey]="userSettings.WheelSymbol"
          [currentSymbol]="currentSymbolForLists$ | async"
          (symbolSelected)="onSetCurrentSymbol($event, 'aggressive')"
          (triggerSmiley)="onChangeSmiley($event)"
          *ngIf="scannerDataAggressive.length > 0; else noData"
        ></app-scanner-symbols-list>
      </div>
    </mat-tab>

    <mat-tab #watchlistTab>
      <ng-template mat-tab-label>
        Watchlist
        <span class="scanner-tab-items-number">
          {{ watchlistTableItems?.length ?? 0 }}
        </span>
        <button
          [matMenuTriggerFor]="watchToggle"
          mat-icon-button
          class="drop show-for-mobile"
        >
          <img
            class="light-theme-img"
            src="assets/img/newImg/Arrow.svg"
            alt=""
          />
          <img
            class="black-theme-img"
            src="assets/img/newImg/Arrow-white.svg"
            alt=""
          />
        </button>
        <mat-menu
          #watchToggle="matMenu"
          class="ws-menu"
        >
          <button
            (click)="addSectionIntoGroupedWatchlist()"
            mat-menu-item
          >
            <mat-icon
              [svgIcon]="'add-section'"
              class="middle-icon"
            ></mat-icon>
            <span>Add section</span>
          </button>
        </mat-menu>
      </ng-template>
      <div class="grouped-watchlist-container">
        <app-grouped-watchlist
          [isActive]="watchlistTab.isActive"
          [currentSymbol]="currentSymbolForLists$ | async"
          [watchlistTableItems]="watchlistTableItems"
          [columns]="['flag', 'symbol', 'company']"
          [smileyListType]="smileyListTypes.Wheel"
          [watchlistType]="watchlistTypes.Wheel"
          (symbolSelected)="onSetCurrentSymbol($event)"
          (smileyUpdated)="onChangeSmiley({ security_id: $event.data.security_id, flag: $event.flag })"
          #groupedWatchlist
        ></app-grouped-watchlist>
      </div>
    </mat-tab>

    <mat-tab *appHideForUnavailableFeature="features.TradingLog">
      <ng-template mat-tab-label>
        <div class="label-portfolio">
          <mat-icon
            [svgIcon]="'info_icon'"
            [matTooltip]="'Portfolio includes trades from Active tab of Trading Log.'"
            [matTooltipPosition]="'right'"
            [matTooltipHideDelay]="0"
            class="small-icon primary portfolio-info-icon"
          ></mat-icon>
          <span>Portfolio</span>
        </div>
      </ng-template>
      <app-portfolio
        [isActive]="selectedTab === 'portfolio'"
        [settingsPrefix]="'[wheel]'"
        (symbolSelected)="onSetCurrentSymbol($event)"
        (filteredPortfolioListUpdated)="onUpdateFilteredPortfolioList($event)"
      ></app-portfolio>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #noData>
  <div class="no-data-msg">
    <div class="no-data-img"></div>
    <div class="text-wrapper text-left">
      <p class="title">Right now, no stocks meet the filter criteria. You may consider changing the filters.</p>
      <p class="text">
        We're checking every 2 minutes, and as soon as we find great candidates, we will display them here.
      </p>
      <p class="all-good">
        The scanner is working just fine
        <img
          src="assets/img/wheel/scanner/good.svg"
          alt="smile"
        />
      </p>
    </div>
  </div>
</ng-template>
