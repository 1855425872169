import { Directive, inject } from '@angular/core';
import { EntityId, IChartingLibraryWidget, IChartWidgetApi, IDropdownApi } from '@chart/charting_library';

import { ProfitLossIndicatorOptions } from '@constants/indicators/profit-loss-indicator.constants';
import { ChartSizeService } from '@s/chart-size.service';
import { ISymbol } from '@s/symbols.service';

@Directive()
export class ProfitLossIndicator {
  private dropdownApi: IDropdownApi | null = null;
  private profitLossStudyId: EntityId | null = null;
  private profitLossFilteredStudyId: EntityId | null = null;

  private profitLossSymbolAdjusted: string | null = null;

  private chartSizeService = inject(ChartSizeService);

  public async drawDropdown(
    widget: IChartingLibraryWidget,
    dropdownOptions: ProfitLossIndicatorOptions[],
    onDropdownUpdate: (option: ProfitLossIndicatorOptions) => Promise<void>,
    getSelectedOption: () => ProfitLossIndicatorOptions,
  ): Promise<void> {
    if (!widget) {
      return;
    }

    if (this.dropdownApi) {
      this.dropdownApi.remove();
    }

    const items = [...dropdownOptions].map((option) => ({
      title: option,
      onSelect: async (): Promise<void> => {
        this.dropdownApi.applyOptions({
          title: `P&L: ${option}`,
        });

        await onDropdownUpdate(option);
      },
    }));

    const selectedOption = getSelectedOption();

    this.dropdownApi = await widget.createDropdown({
      title: `P&L: ${selectedOption}`,
      items,
    });
  }

  public removeDropdown(): void {
    if (this.dropdownApi) {
      this.dropdownApi.remove();
    }
  }

  public async showProfitLossStudy(
    activeChart: IChartWidgetApi,
    getSelectedValue: () => ProfitLossIndicatorOptions,
    getIsDataHasFilter: () => boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFilteredTrades: () => any,
    getSelectedSymbol: () => Promise<ISymbol>,
  ): Promise<void> {
    if (!activeChart) {
      return;
    }

    this.removeProfitLoss(activeChart);

    const selectedValue = getSelectedValue();
    if (selectedValue === ProfitLossIndicatorOptions.None) {
      return;
    }

    if (!this.profitLossStudyId) {
      this.profitLossStudyId = await activeChart.createStudy('FirstProfitLoss', false, true);
      this.chartSizeService.setChartSize(activeChart);
    }

    // re-create profit-loss-filtered study, otherwise it will not be updated
    if (this.profitLossFilteredStudyId) {
      activeChart.removeEntity(this.profitLossFilteredStudyId);
      this.profitLossFilteredStudyId = null;
    }

    const isHasFilter = getIsDataHasFilter();

    const isAddFilteredProfitLoss = isHasFilter && !this.profitLossFilteredStudyId;
    const isRemoveFilteredProfitLoss = !isHasFilter && this.profitLossFilteredStudyId;

    if (isAddFilteredProfitLoss) {
      this.profitLossFilteredStudyId = await activeChart.createStudy('SecondProfitLoss', false, true);
      this.chartSizeService.setChartSize(activeChart);
    }

    if (isRemoveFilteredProfitLoss) {
      activeChart.removeEntity(this.profitLossFilteredStudyId);
      this.profitLossFilteredStudyId = null;
    }

    if (isHasFilter) {
      await this.adjustPeriodProfitLossCharts(activeChart, getIsDataHasFilter, getFilteredTrades, getSelectedSymbol);
    }
  }

  public removeProfitLoss(activeChart: IChartWidgetApi): void {
    if (!activeChart) {
      return;
    }

    if (this.profitLossStudyId) {
      activeChart.removeEntity(this.profitLossStudyId);
      this.profitLossStudyId = null;
    }

    if (this.profitLossFilteredStudyId) {
      activeChart.removeEntity(this.profitLossFilteredStudyId);
      this.profitLossFilteredStudyId = null;
    }
  }

  public getShapesIds(): EntityId[] {
    const entities = [];

    if (this.profitLossStudyId) {
      entities.push(this.profitLossStudyId);
    }

    if (this.profitLossFilteredStudyId) {
      entities.push(this.profitLossFilteredStudyId);
    }

    return entities;
  }

  private async adjustPeriodProfitLossCharts(
    activeChart: IChartWidgetApi,
    getIsDataHasFilter: () => boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFilteredTrades: () => any,
    getSelectedSymbol: () => Promise<ISymbol>,
  ): Promise<void> {
    const isHasFilter = getIsDataHasFilter();
    const filteredTrades = getFilteredTrades();
    const selectedSymbol = await getSelectedSymbol();

    if (!isHasFilter || !Object.keys(filteredTrades).length) {
      return;
    }

    if (!selectedSymbol || selectedSymbol.symbol === this.profitLossSymbolAdjusted) {
      return;
    }

    this.profitLossSymbolAdjusted = selectedSymbol.symbol;

    const visibleRange = activeChart.getVisibleRange();
    await activeChart.setVisibleRange(visibleRange);
  }
}
