import { EntityId, IChartWidgetApi } from '@chart/charting_library';
import * as _ from 'lodash';
import moment from 'moment';

import { Themes } from '@const';
import { ISymbolData } from '@core/types';
import { IWheelFilter } from '@t/wheel/wheel.types';

// TODO: get ready to use value, do not use domain-specific logic here
export class MinStrikePriceIndicator {
  private _minStrikePriceLineId: EntityId | null = null;
  private _symbolData: ISymbolData | null = null;
  private _symbolFilteredData: ISymbolData[] | null = null;
  private _symbolFullData: ISymbolData[] | null = null;
  private _symbolFilters: IWheelFilter | null = null;
  private _activeChart: IChartWidgetApi | null = null;
  private _currentTheme: Themes = Themes.Light;

  private _colors: Record<Themes, string> = {
    [Themes.Light]: '#A100ED',
    [Themes.Dark]: '#D680FF',
  };

  constructor(
    symbolFullData: ISymbolData[],
    symbolFilters: IWheelFilter,
    activeChart: IChartWidgetApi,
    currentTheme: Themes,
  ) {
    this.symbolFullData = symbolFullData;
    this.symbolFilters = symbolFilters;
    this.activeChart = activeChart;
    this._currentTheme = currentTheme;
    this.calculateDataForLine();
  }

  public get minStrikePriceLineId(): EntityId | null {
    return this._minStrikePriceLineId;
  }

  public set currentTheme(theme: Themes) {
    this._currentTheme = theme;
    this.showLine(this._symbolData?.security_id);
  }

  public set activeChart(chart: IChartWidgetApi) {
    this._activeChart = chart;
    this.calculateDataForLine();
    this.showLine(this._symbolData?.security_id);
  }

  public set symbolFilters(filters: IWheelFilter) {
    this._symbolFilters = filters;
    this.calculateDataForLine();
    this.showLine(this._symbolData?.security_id);
  }

  public set symbolFullData(symbolData: ISymbolData[]) {
    this._symbolFullData = symbolData;
    this.calculateDataForLine();
    this.showLine(this._symbolData?.security_id);
  }

  public showLine(symbolSecurityId: number): void {
    if (!this._activeChart || !this._symbolFilteredData || symbolSecurityId !== this._symbolData?.security_id) {
      return;
    }

    this.removeLine();

    // do not render line if there is no data
    if (!this._symbolFilteredData || this._symbolFilteredData.length === 0) {
      return;
    }

    const chartStrikeText = this.getChartStrikeText();

    this._minStrikePriceLineId = this._activeChart.createMultipointShape(
      [{ time: moment().unix().valueOf(), price: this._symbolData.strike_price }],
      {
        shape: 'horizontal_line',
        lock: true,
        zOrder: 'top',
        overrides: {
          linecolor: this._colors[this._currentTheme],
          textcolor: this._colors[this._currentTheme],
          linestyle: 2,
          linewidth: 1,
          showLabel: true,
          horzLabelsAlign: 'right',
        },
        disableSelection: true,
        disableUndo: true,
        disableSave: false,
        text: chartStrikeText,
      },
    );
  }

  public removeLine(): void {
    if (this._minStrikePriceLineId && this._activeChart) {
      this._activeChart.removeEntity(this._minStrikePriceLineId);
      this._minStrikePriceLineId = null;
    }
  }

  private getChartStrikeText(): string {
    if (!this._symbolFilteredData?.length || !this._symbolData) {
      return;
    }

    let strikeText = 'Min Strike: ';
    this._symbolFilteredData
      .filter((data) => data.strike_price === this._symbolData.strike_price)
      .sort((a, b) => moment(a.expiration).unix() - moment(b.expiration).unix())
      .forEach((data, index) => {
        strikeText += `${index === 0 ? '' : ' / '}${moment(data.expiration).format('MMM D')} - ${data.annularized}%`;
      });
    return strikeText;
  }

  private calculateDataForLine(): void {
    if (this._symbolFullData && this._symbolFullData.length) {
      this._symbolFullData = _.sortBy(this._symbolFullData, (o) => o.strike_price);
      this._symbolFilteredData = _.sortBy(this._symbolFullData, (o) => o.strike_price);
      this._symbolData = this._symbolFullData[0];
    } else {
      this._symbolFullData = null;
      this._symbolFilteredData = [];
      this._symbolData = null;
    }
  }
}
