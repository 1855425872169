import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { OverlayRef } from '@angular/cdk/overlay';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { createMask } from '@ngneat/input-mask';
import * as _ from 'lodash';
import moment from 'moment';
import { Subject, Subscription, first, from } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { IAddTradingLogTradeData } from '@c/trading-log/shared/add-trading-log-trade/add-trading-log-trade.model';
import {
  Countries,
  EasternTimeZoneName,
  ExchangeCountriesCodes,
  Features,
  MomentDateTimeFormats,
  UserSettings,
  WheelROIDefaultRange,
  changeCalcSymbolDebounceTime,
  formatDecimal,
  round,
} from '@const';
import { roiPartLegendForTooltip } from '@constants/tooltips';
import { CommaSeperatorPipe } from '@core1/directives/comma-seperator.directive';
import { ObservableService } from '@core1/directives/observable.service';
import { IWheelCalculator, WheelCalculator } from '@core1/model/wheel-calc.model';
import { OpenedAddTradeMenuDetailsModel } from '@m1/wheel/wheel-calculator/wheel-calculator.model';
import { IRawHoliday } from '@mod/data/holidays.model';
import { ILiveSubscription, IOption, IOptionsChain } from '@mod/live-data/live-data.model';
import {
  TradingOrderClass,
  TradingOrderDuration,
  TradingOrderType,
} from '@mod/trading-panel/trading-panel-order.model';
import { TradierOrderSide } from '@mod/trading-panel/trading-panel.model';
import { EditionsService } from '@s/editions.service';
import { HistoricalDataService } from '@s/historical-data.service';
import { HolidaysService } from '@s/holidays.service';
import { LiveWheelCalculatorStreamingService } from '@s/live-wheel-calculator-streaming.service';
import { MarketTimeService } from '@s/market-time.service';
import { ObservableService as ObservableServiceV2 } from '@s/observable.service';
import { StreamingService } from '@s/streaming.service';
import { ISymbol, SymbolsService } from '@s/symbols.service';
import { TradingPanelService } from '@s/trading-panel.service';
import { UserDataService } from '@s/user-data.service';
import { TradingLogTransactionType } from '@t/trading-log';
import { DataWindowUpdateSource } from '@t/wheel/wheel.types';
import { convertToEasternTime, isNullOrUndefinedOrEmpty, removeComma, round as roundV2 } from '@u/utils';
import { WheelService } from '../wheel.service';
import * as calc from './formula';
import { MaxPopupComponent } from './max-popup/max-popup.component';
import { PrintWheelPopupComponent } from './print-wheel-popup/print-wheel-popup.component';
import { WheelCalculatorService } from './wheel-calculator.service';

@Component({
  selector: 'app-wheel-calculator',
  templateUrl: './wheel-calculator.component.html',
  styleUrls: ['./wheel-calculator.component.scss'],
})
export class WheelCalculatorComponent implements OnInit, OnDestroy {
  protected putProfit90Tooltip = `90% Profit Target is the calculated exit to achieve 90% of the Max Profit on the trade.\n\n90% Profit Value is the actual profit at 90% based on the position size.`;
  protected callProfit90Tooltip = `90% Profit Target is the calculated exit to achieve 90% of the Max Profit on the trade.\n\n90% Profit Value is the actual profit at 90% based on the position size.`;
  protected callMaxProfit90Tooltip = `Profit Potential 90% Max Profit Target is the combined exit for the covered call (stock and option) to achieve 90% of the Max Profit on the trade.\n\n90% Profit Value is the dollar value of the profit based on a 90% Max Profit exit.`;
  protected roiLegend = `The annualized ROI for the PUT credit received.
    ${roiPartLegendForTooltip}
  `;

  protected roiLegendCall = `The annualized ROI for the CALL credit received. \n ${roiPartLegendForTooltip}`;

  public features = Features;
  protected tlTransactionTypes = TradingLogTransactionType;
  protected;
  stocks: { headerId: number }[] = [
    { headerId: 1 },
    { headerId: 2 },
    { headerId: 3 },
    { headerId: 4 },
    { headerId: 5 },
  ];

  put_headers: { header_id: number; name: string; value: string }[] = [];
  put_stock_price: { header_id: number; name: string; value: string }[] = [];

  put_strike_price: { header_id: number; name: string; value: string }[] = [];

  put_premium: { header_id: number; name: string; value: string; min: number; max: number }[] = [];

  put_dte: { header_id: number; name: string; expiration: string; dte: number }[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put_option_to_trade: { header_id: number; name: string; value: any; need_to_buy: number }[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  drop: { header_id: number; name: string; value: any }[] = [];

  put_premium_per_day: { header_id: number; name: string; premium_per_day: number; premium_collected: number }[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put_premium_annualarized: { header_id: number; name: string; value: any }[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put_profit: { header_id: number; name: string; taking: number; value: any }[] = [];

  call_symbol: { header_id: number; name: string; value: string }[] = [];

  call_stock_price: { header_id: number; name: string; value: string }[] = [];

  call_no_of_shares: { header_id: number; name: string; value: string }[] = [];

  call_strike_price: { header_id: number; name: string; value: string }[] = [];

  call_option_premium: { header_id: number; name: string; min: number; value: string }[] = [];

  call_expiration: { header_id: number; name: string; expiration: string; dte: number }[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  call_option_to_trade: { header_id: number; name: string; value: any; position_value: number }[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rise: { header_id: number; name: string; value: any }[] = [];

  call_premium_per_day: { header_id: number; name: string; premium_per_day: number; premium_collected: number }[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  call_premium_annularized: { header_id: number; name: string; value: any }[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  call_profit: { header_id: number; name: string; taking: number; value: any }[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  call_total_gain: { header_id: number; name: string; value: any }[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  call_stock_gain: { header_id: number; name: string; excercised: number; holding: any }[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  call_max_profit: { header_id: number; name: string; taking: number; value: any }[] = [];

  put_min_premiums: Array<Array<{ day: string; dte: number; min_roi: number }>> = [];
  call_min_premiums: Array<Array<{ day: string; dte: number; min_roi: number }>> = [];

  roiLowerBound: number = WheelROIDefaultRange.Lower;
  roiUpperBound: number = WheelROIDefaultRange.Upper;

  private userId = null;

  private s_date: string; // By jainam

  private fields = [
    'put_headers',
    'put_stock_price',
    'put_strike_price',
    'put_premium',
    'put_dte',
    'put_option_to_trade',
    'drop',
    'put_premium_per_day',
    'put_premium_annualarized',
    'put_profit',
    'call_symbol',
    'call_stock_price',
    'call_no_of_shares',
    'call_strike_price',
    'call_option_premium',
    'call_expiration',
    'call_option_to_trade',
    'rise',
    'call_premium_per_day',
    'call_premium_annularized',
    'call_profit',
    'call_total_gain',
    'call_stock_gain',
    'call_max_profit',
  ]; // By Jainam

  protected openedAddTradeMenuFor: OpenedAddTradeMenuDetailsModel | null = null;

  private userSetting = JSON.parse(localStorage.getItem('user'));

  dates: string[] = [];

  noOfColumns: number;

  buyingPower: number;
  holidayList: string[] = [];
  private oneYearRange;

  showPutRow = true;
  showCallRow = true;
  collapseApply = false;

  private data: IWheelCalculator[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  maxPosition: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  typingTimer: any;

  rowspan = 26;
  doneTypingInterval = 500;

  private _triggetToSave: Subscription;
  private getHolidays: Subscription;
  refreshStock$: Subscription;
  updateUserSetting$: Subscription;

  tradeLinkTooltip: string;
  tradeLinkTooltipEnabled = true;
  tradierFlags;

  profitLinkTooltipText: string = `Clicking this will open the Tradier platform and pre-fill an order.
    \n <b>ATTENTION! Please adjust order Quantity based on your initial entry order.</b>`;

  currentDate: string = moment().format('YYYY-MM-DD');

  currencyInputMask = createMask({
    alias: 'numeric',
    groupSeparator: ',',
    digits: 2,
    digitsOptional: false,
    showMaskOnHover: false,
    rightAlign: false,
    allowMinus: false,
    placeholder: '0.00',
  });

  stockPurchasePriceInputMask = createMask({
    alias: 'numeric',
    groupSeparator: ',',
    digits: 2,
    digitsOptional: false,
    showMaskOnHover: false,
    rightAlign: false,
    allowMinus: false,
    placeholder: '0.00',
  });

  stockPriceInputMask = createMask({
    alias: 'numeric',
    groupSeparator: ',',
    digits: 2,
    digitsOptional: false,
    showMaskOnHover: false,
    rightAlign: false,
    allowMinus: false,
    placeholder: '0.00',
  });

  strikePriceInputMask = createMask({
    alias: 'numeric',
    groupSeparator: ',',
    digits: 2,
    digitsOptional: false,
    showMaskOnHover: false,
    rightAlign: false,
    allowMinus: false,
    placeholder: '0.00',
  });

  sharesInputMask = createMask({
    alias: 'numeric',
    groupSeparator: ',',
    digits: 0,
    digitsOptional: false,
    showMaskOnHover: false,
    rightAlign: false,
    allowMinus: false,
    placeholder: '0',
  });

  private allSymbols: Record<string, ISymbol> = {};
  private changeDataWindowSymbol$ = new Subject<string>();
  private saveDataWindowSource$ = new Subject<DataWindowUpdateSource>();
  private subscriber = new Subscription();
  private wheelROIRangeSubscription: Subscription = null;

  public putSubscription: ILiveSubscription = null;
  public putOptionsChain: IOptionsChain = null;
  public putSubscriptionTimeout: NodeJS.Timeout = null;
  public isPutSubscriptionTimeoutTriggered: boolean = false;
  public isPutUpdateOverlay: boolean = false;
  public putOverlayTimeout: NodeJS.Timeout = null;
  private putBuyingPower: number = 10000000;

  public callSubscription: ILiveSubscription = null;
  public callStockPrice: number = null;
  public callOptionsChain: IOptionsChain = null;
  public callSubscriptionTimeout: NodeJS.Timeout = null;
  public isCallSubscriptionTimeoutTriggered: boolean = false;
  public isCallUpdateOverlay: boolean = false;
  public callOverlayTimeout: NodeJS.Timeout = null;
  private callNumberOfShares: number = 100;

  public indexOfActiveCol: number;

  constructor(
    private commaSeperatorPipe: CommaSeperatorPipe,
    private wheelCalculatorService: WheelCalculatorService,
    public dialog: MatDialog,
    private wheelService: WheelService,
    private observableService: ObservableService,
    private observableService2: ObservableServiceV2,
    private holidaysService: HolidaysService,
    private symbolsService: SymbolsService,
    private userDataService: UserDataService,
    private streamingService: StreamingService,
    private liveWheelCalculatorStreamingService: LiveWheelCalculatorStreamingService,
    private historicalDataService: HistoricalDataService,
    private marketTimeService: MarketTimeService,
    private editionsService: EditionsService,
    private tradingPanelService: TradingPanelService,
    private ngZone: NgZone,
  ) {
    this.wheelROIRangeSubscription = this.observableService.wheelRoiRange.subscribe((res) => {
      this.roiLowerBound = res.lower;
      this.roiUpperBound = res.upper;
    });
  }

  protected onMenuResized(menuTrigger: MatMenuTrigger): void {
    this.ngZone.onStable.pipe(first()).subscribe(() => {
      this.recalculateMenu(menuTrigger);
    });
  }

  protected recalculateMenu(menuTrigger: MatMenuTrigger): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const overlayRef: OverlayRef = (menuTrigger as any)._overlayRef;
    const overlayConfig = overlayRef.getConfig();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (overlayConfig.positionStrategy as any)._isInitialRender = true;
    overlayConfig.positionStrategy.apply();
    menuTrigger.updatePosition();
  }

  protected onOpenAddTradeMenu(index: number, type: 'put' | 'call'): void {
    if (type === 'put') {
      const sellPutData = {
        symbol: this.put_headers[index].value,
        put_stock_price: this.put_stock_price[index].value,
        put_strike_price: this.put_strike_price[index].value,
        put_premium: this.put_premium[index].value,
        put_dte: this.put_dte[index].dte,
        put_dte_expiration: this.put_dte[index].expiration,
        put_option_to_trade: this.put_option_to_trade[index].value,
        drop: this.drop[index].value,
        put_premium_per_day: this.put_premium_per_day[index].premium_per_day,
        premium_per_day_collected: this.put_premium_per_day[index].premium_collected,
        put_premium_annualarized: this.put_premium_annualarized[index].value,
        put_profit: this.put_profit[index].value,
        put_profit_taking: this.put_profit[index].taking,
      };

      const putItemData = {
        symbol: sellPutData.symbol ? sellPutData.symbol.trim().toUpperCase() : '',
        date: moment().format(MomentDateTimeFormats.ServerDate),
        time: moment().format(MomentDateTimeFormats.FullTime),
        type: TradingLogTransactionType.SellPut,
        expiration: sellPutData.put_dte_expiration,
        strike: isNullOrUndefinedOrEmpty(sellPutData.put_strike_price)
          ? null
          : roundV2(Number(removeComma(sellPutData.put_strike_price)), 2),
        qty: isNullOrUndefinedOrEmpty(sellPutData.put_option_to_trade)
          ? null
          : roundV2(sellPutData.put_option_to_trade, 2),
        price: isNullOrUndefinedOrEmpty(sellPutData.put_premium)
          ? null
          : roundV2(Number(removeComma(sellPutData.put_premium)), 2),
      };

      const specificPutValuesForTransactionTypes = new Map<TradingLogTransactionType, IAddTradingLogTradeData>();

      specificPutValuesForTransactionTypes.set(TradingLogTransactionType.SellPut, {
        price: isNullOrUndefinedOrEmpty(sellPutData.put_premium)
          ? null
          : roundV2(Number(removeComma(sellPutData.put_premium)), 2),
      });
      specificPutValuesForTransactionTypes.set(TradingLogTransactionType.BuyPut, {
        // important: "profitLinkShow" to have the same value as in UI (put_profit_taking can be 0 after delete cell content)
        price:
          !isNullOrUndefinedOrEmpty(sellPutData.put_profit_taking) && this.profitLinkShow(index, true)
            ? roundV2(Number(sellPutData.put_profit_taking), 2)
            : null,
      });

      this.openedAddTradeMenuFor = {
        index,
        type,
        data: putItemData,
        specificValuesForAvailableTransactionTypes: specificPutValuesForTransactionTypes,
      };

      return;
    }

    const sellCallData = {
      symbol: this.call_symbol[index].value,
      call_stock_price: this.call_stock_price[index].value,
      call_no_of_shares: this.call_no_of_shares[index].value,
      call_strike_price: this.call_strike_price[index].value,
      call_option_premium: this.call_option_premium[index].value,
      call_dte: this.call_expiration[index].dte,
      call_expiration: this.call_expiration[index].expiration,
      call_option_to_trade: this.call_option_to_trade[index].value,
      call_option_to_trade_position_value: this.call_option_to_trade[index].position_value,
      rise: this.rise[index].value,
      call_premium_per_day: this.call_premium_per_day[index].premium_per_day,
      call_premium_per_day_collected: this.call_premium_per_day[index].premium_collected,
      call_premium_annularized: this.call_premium_annularized[index].value,
      call_profit: this.call_profit[index].value,
      call_profit_taking: this.call_profit[index].taking,
      call_total_gain: this.call_total_gain[index].value,
      call_stock_gain_holding: this.call_stock_gain[index].holding,
      call_stock_gain_holding_excercised: this.call_stock_gain[index].excercised,
      call_max_profit: this.call_max_profit[index].value,
      call_max_profit_taking: this.call_max_profit[index].taking,
    };

    const callItemData = {
      symbol: sellCallData.symbol ? sellCallData.symbol.trim().toUpperCase() : '',
      date: moment().format(MomentDateTimeFormats.ServerDate),
      time: moment().format(MomentDateTimeFormats.FullTime),
      type: TradingLogTransactionType.SellCall,
      expiration: sellCallData.call_expiration,
      strike: isNullOrUndefinedOrEmpty(sellCallData.call_strike_price)
        ? null
        : roundV2(Number(removeComma(sellCallData.call_strike_price)), 2),
      qty: isNullOrUndefinedOrEmpty(sellCallData.call_option_to_trade)
        ? null
        : roundV2(Number(sellCallData.call_option_to_trade), 2),
      price: isNullOrUndefinedOrEmpty(sellCallData.call_option_premium)
        ? null
        : roundV2(Number(removeComma(sellCallData.call_option_premium)), 2),
    };

    const specificCallValuesForTransactionTypes = new Map<TradingLogTransactionType, IAddTradingLogTradeData>();

    specificCallValuesForTransactionTypes.set(TradingLogTransactionType.SellCall, {
      price: isNullOrUndefinedOrEmpty(sellCallData.call_option_premium)
        ? null
        : roundV2(Number(removeComma(sellCallData.call_option_premium)), 2),
    });
    specificCallValuesForTransactionTypes.set(TradingLogTransactionType.BuyCall, {
      // important: "profitLinkShow" to have the same value as in UI (call_profit_taking can be 0 after delete cell content)
      price:
        !isNullOrUndefinedOrEmpty(sellCallData.call_profit_taking) && this.profitLinkShow(index, false)
          ? roundV2(Number(sellCallData.call_profit_taking), 2)
          : null,
    });

    this.openedAddTradeMenuFor = {
      index,
      type,
      data: callItemData,
      specificValuesForAvailableTransactionTypes: specificCallValuesForTransactionTypes,
    };
  }

  private selectDataWindowSymbol(index: number, section: 'put' | 'call'): void {
    // use data from inputs (unsaved), otherwise take from saved data: this.data[index][`${section}_symbol`];
    let ticker = '';

    if (section === 'put') {
      ticker = this.put_headers[index].value;
    } else if (section === 'call') {
      ticker = this.call_symbol[index].value;
    }

    if (ticker) {
      this.changeDataWindowSymbol$.next(ticker.toUpperCase());
    }
  }

  public onFocusIn(index: number, section: 'put' | 'call'): void {
    this.selectDataWindowSymbol(index, section);
  }

  public onFocusOut(index: number, header_id: number, section: 'put' | 'call'): void {
    this.selectDataWindowSymbol(index, section);

    const isPut = section === 'put';
    if (
      (isPut && this.putSubscription?.headerId === header_id) ||
      (!isPut && this.callSubscription?.headerId === header_id)
    ) {
      const ticker = isPut ? this.put_headers[index].value : this.call_symbol[index].value;

      if (ticker) {
        this.subscribeLiveData(header_id, isPut);
      } else if (isPut && this.putSubscription) {
        this.unsubscribePutLiveData();
      } else if (!isPut && this.callSubscription) {
        this.unsubscribeCallLiveData();
      }
    }
  }

  /**
   * By Jainam
   */
  private setDefaultData(): void {
    this.fields.forEach((s) => {
      for (let i = 0; i < 6; i++) {
        let obj = {};
        if (
          s !== 'call_stock_gain' &&
          s !== 'put_dte' &&
          s !== 'call_expiration' &&
          s !== 'put_premium_per_day' &&
          s !== 'call_premium_per_day'
        ) {
          obj = { header_id: i + 1, name: 'stock' + (i + 1), value: '' };
        }
        if (s === 'put_premium' || s === 'call_option_premium') {
          obj = { ...obj, min: null, max: null };
        }
        if (s === 'put_dte' || s === 'call_expiration') {
          obj = { header_id: i + 1, name: 'stock' + (i + 1), expiration: null, dte: null };
        }
        if (s === 'put_option_to_trade') {
          obj = { ...obj, need_to_buy: null };
        }
        if (s === 'put_premium_per_day' || s === 'call_premium_per_day') {
          obj = { header_id: i + 1, name: 'stock' + (i + 1), premium_per_day: null, premium_collected: null };
        }
        if (s === 'put_profit' || s === 'call_profit' || s === 'call_max_profit') {
          obj = { ...obj, taking: null };
        }
        if (s === 'call_option_to_trade') {
          obj = { ...obj, position_value: null };
        }
        if (s === 'call_stock_gain') {
          obj = { header_id: i + 1, name: 'stock' + (i + 1), excercised: null, holding: null };
        }
        this[s].push(obj);
      }
    });
  }

  selling(value): void {
    if (value == 'puts') {
      this.showPutRow = !this.showPutRow;
    } else {
      this.showCallRow = !this.showCallRow;
    }
  }

  collapseColumn(): void {
    this.collapseApply = !this.collapseApply;
  }

  openPrintWheel(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['print-options', 'wheel-print', 'modals'];
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(PrintWheelPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {});
  }

  checkIfEmpty(value: string, is_put: boolean, index: number): boolean {
    if (value === 'strike') {
      if (is_put) {
        return this.put_strike_price[index].value === null || this.put_strike_price[index].value === '';
      } else {
        return this.call_strike_price[index].value === null || this.call_strike_price[index].value === '';
      }
    }
    if (value === 'stock') {
      if (is_put) {
        return this.put_stock_price[index].value === null || this.put_stock_price[index].value === '';
      } else {
        return this.call_stock_price[index].value === null || this.call_stock_price[index].value === '';
      }
    }
    if (value === 'expiration') {
      if (is_put) {
        return (
          this.put_dte[index]?.expiration === null ||
          this.put_dte[index]?.expiration === '' ||
          this.checkIfDateInThePast(this.put_dte[index]?.expiration)
        );
      } else {
        return (
          this.call_expiration[index]?.expiration === null ||
          this.call_expiration[index]?.expiration === '' ||
          this.checkIfDateInThePast(this.call_expiration[index]?.expiration)
        );
      }
    }
    if (value === 'share') {
      return this.call_no_of_shares[index].value === null || this.call_no_of_shares[index].value === '';
    }
    if (value === 'premium') {
      if (is_put) {
        return this.put_premium[index].value === null || this.put_premium[index].value === '';
      } else {
        return this.call_option_premium[index].value === null || this.call_option_premium[index].value === '';
      }
    }
    if (value === 'ticker') {
      if (is_put) {
        return this.put_headers[index].value === null || this.put_headers[index].value === '';
      } else {
        return this.call_symbol[index].value === null || this.call_symbol[index].value === '';
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  tableDrop(event: CdkDragDrop<string[]>): void {}

  async ngOnInit(): Promise<void> {
    this.oneYearRange = {
      from: moment().tz(EasternTimeZoneName).format('YYYY-MM-DD'),
      to: moment().tz(EasternTimeZoneName).add(1, 'year').endOf('year').format('YYYY-MM-DD'),
    };
    await this.setDefaultData();
    if (!Object.keys(this.userSetting).length) {
      this.userSetting = JSON.parse(localStorage.getItem('user'));
    }
    this.userId = this.userSetting.userId;
    this.loadData();
    this.refreshStock$ = this.wheelCalculatorService.refreshStock$.subscribe((res) => {
      if (res) {
        this.loadData(res);
      }
    });
    this.updateUserSetting$ = this.wheelCalculatorService.updateUserSetting$.subscribe((res) => {
      if (res && Object.keys(res).length > 0) {
        this.onUpdate()
          .then(() => {})
          .catch(() => {});
      }
    });
    this._triggetToSave = this.wheelService.getTriggerToSaveCalc$().subscribe(async (res) => {
      if (res) {
        await this.convertToDB();
        this.wheelCalculatorService.save(this.data).subscribe(() => {});
      }
    });

    const { tradier_flag_ifs, tradier_flag_pxo } = this.observableService2.mySettings.getValue();
    this.tradierFlags = {
      tradier_flag_ifs,
      tradier_flag_pxo,
    };
    this.observableService2.mySettings.subscribe((mySettings) => {
      const { tradier_flag_ifs, tradier_flag_pxo } = mySettings;
      this.tradierFlags = {
        tradier_flag_ifs,
        tradier_flag_pxo,
      };
    });

    this.subscriber.add(
      from(this.symbolsService.getAll()).subscribe((allSymbols) => {
        this.allSymbols = allSymbols.reduce((acc, item) => {
          if (item.country_code === ExchangeCountriesCodes.US) {
            acc[item.symbol] = item;
          }

          return acc;
        }, {});
      }),
    );

    this.subscriber.add(
      this.changeDataWindowSymbol$
        .pipe(debounceTime(changeCalcSymbolDebounceTime), distinctUntilChanged())
        .subscribe(async (symbolName) => {
          const symbol = this.allSymbols[symbolName];

          if (symbol) {
            this.wheelService.updateDataWindowSymbol$.next({
              source: DataWindowUpdateSource.ChartSearchBar,
              symbol: { name: symbol.symbol, id: symbol.security_id },
            });

            // for case when data-window is inactive or hidden
            this.saveDataWindowSource$.next(DataWindowUpdateSource.ChartSearchBar);
            await this.userDataService.set(UserSettings.WheelSymbol, symbol.security_id);
          }
        }),
    );

    this.subscriber.add(
      this.saveDataWindowSource$.pipe(distinctUntilChanged()).subscribe(async (source) => {
        await this.userDataService.set(UserSettings.WheelDataWindowUpdateSource, source);
      }),
    );

    const wheelROIRanges = this.observableService2.wheelRoiRange.getValue();
    this.roiLowerBound = wheelROIRanges.lower;
    this.roiUpperBound = wheelROIRanges.upper;
  }

  expirationToggle(): void {
    // toggle scroll in conservative/aggressive tabs
    this.observableService.isAbletoUpDown.next(false);
  }

  public addBorderColor(event): void {
    event.target.parentElement.style.border = '1px solid #2196F3';
    // disable scroll in conservative/aggressive tabs
    this.observableService.isAbletoUpDown.next(false);
  }

  removeBorderColor(event): void {
    event.target.parentElement.style.border = '0';
    // enable scroll in conservative/aggressive tabs
    this.observableService.isAbletoUpDown.next(true);
  }

  private getListOfFridays(): string[] {
    const friday = moment().day(5);
    const arr = [friday.format('YYYY-MM-DD')];
    while (arr.length < 8) {
      friday.add(7, 'days');
      arr.push(friday.format('YYYY-MM-DD'));
    }
    return arr;
  }

  getBGLightColor(index: number, is_put: boolean): string {
    if (!this.checkIfEmpty('expiration', is_put, index)) {
      if (is_put) {
        if (this.put_premium_annualarized[index].value > this.roiUpperBound) {
          return 'light-bg-yellow';
        } else if (
          this.put_premium_annualarized[index].value >= this.roiLowerBound &&
          this.put_premium_annualarized[index].value <= this.roiUpperBound
        ) {
          return 'light-bg-green';
        } else if (
          this.put_premium_annualarized[index].value < this.roiLowerBound &&
          this.put_premium_annualarized[index].value > 0
        ) {
          return 'light-bg-red';
        }
      } else {
        if (this.call_premium_annularized[index].value > this.roiUpperBound) {
          return 'light-bg-yellow';
        } else if (
          this.call_premium_annularized[index].value >= this.roiLowerBound &&
          this.call_premium_annularized[index].value <= this.roiUpperBound
        ) {
          return 'light-bg-green';
        } else if (
          this.call_premium_annularized[index].value < this.roiLowerBound &&
          this.put_premium_annualarized[index].value > 0
        ) {
          return 'light-bg-red';
        }
      }
    }
  }

  private loadData(header_id?: number): void {
    this.dates = this.getListOfFridays();
    this.getHolidays = this.holidaysService.get(this.oneYearRange).subscribe((holidays: Array<IRawHoliday>) => {
      if (!holidays) {
        return;
      }
      holidays = holidays.filter((holiday) => holiday.country === Countries.USA).reverse();
      this.excludeHolidaysFromDatesList(holidays);
    });
    this.userSetting = JSON.parse(localStorage.getItem('user'));
    this.data = [];
    Promise.all([this.wheelCalculatorService.getWheelCalData(), this.wheelCalculatorService.getUserSetting()]).then(
      ([wheelCalData, userSetting]) => {
        if (wheelCalData) {
          this.data = wheelCalData.filter(
            (wheelCalDataItem) => wheelCalDataItem.date === null || wheelCalDataItem.date === undefined,
          );
          this.s_date = wheelCalData.find(
            (wheelCalDataItem) => wheelCalDataItem.date !== null && wheelCalDataItem.date !== undefined,
          ).date;
        }
        if (userSetting) {
          this.noOfColumns = userSetting.w_column_size;
          this.buyingPower = userSetting.w_buying_power;
          this.maxPosition = userSetting.w_max_position;
          if (this.userSetting) {
            this.userSetting.wColumnSize = this.noOfColumns;
            this.userSetting.wBuyingPower = this.buyingPower;
            this.userSetting.wMaxPosition = this.maxPosition;
          }
        }
        localStorage.setItem('user', JSON.stringify(this.userSetting));
        this.convertToUI(header_id);
      },
    );
  }

  excludeHolidaysFromDatesList(holidays: Array<IRawHoliday>): void {
    holidays.forEach((holiday) => {
      if (!holiday?.date) {
        return;
      }
      const holidayDate = holiday.date.split('T')[0];
      const holidayInDatesListIndex = _.findIndex(this.dates, (date) => date === holidayDate);
      if (holidayInDatesListIndex !== -1) {
        this.dates[holidayInDatesListIndex] = this.isSunday(this.dates[holidayInDatesListIndex])
          ? ''
          : moment(this.dates[holidayInDatesListIndex]).subtract(1, 'day').format('YYYY-MM-DD');
      }
    });
  }

  isSunday(date: string): boolean {
    return moment(date).day() === 1;
  }

  async ngOnDestroy(): Promise<void> {
    this.subscriber.unsubscribe();
    this.unsubscribeAllLiveData();

    await this.convertToDB();
    if (this._triggetToSave) {
      this._triggetToSave.unsubscribe();
    }
    if (this.updateUserSetting$) {
      this.updateUserSetting$.unsubscribe();
    }
    if (this.refreshStock$) {
      this.refreshStock$.unsubscribe();
    }
    if (this.getHolidays) {
      this.getHolidays.unsubscribe();
    }
    if (this.wheelROIRangeSubscription) {
      this.wheelROIRangeSubscription.unsubscribe();
    }
    await this.onSubmitBeforeDestroy();
  }

  private async onSubmitBeforeDestroy(): Promise<void> {
    this.wheelCalculatorService.save(this.data).subscribe(() => {
      this.data = [];
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onKeyUp(_event, header_id: number, field?: any, index?: number): void {
    if (_event.stopPropagation) {
      _event.stopPropagation();
    }

    clearTimeout(this.typingTimer);
    this.onValueProcess(header_id, field, index, field?.startsWith('put_'));

    // below code is for additional input function like .5 => 0.5
    if (
      (field == 'put_stock_price' ||
        field == 'put_strike_price' ||
        field == 'put_premium' ||
        field == 'call_stock_price' ||
        field == 'call_strike_price' ||
        field == 'call_option_premium') &&
      _event.key == '.'
    ) {
      const length = this[field][index].value ? this[field][index].value.length : 0;
      const selectionStart = _event.target.selectionStart;
      const selectionEnd = _event.target.selectionEnd;
      if (length == 1) {
        this[field][index].value = Number(this[field][index].value).toFixed(2);
      }
      const lengthBeforeDecimal = this[field][index].value
        ? this[field][index].value.toString().split('.')[0].length <= 3
          ? this[field][index].value.toString().split('.')[0].length
          : this[field][index].value.split('.')[0].length + 1
        : 0;

      if (
        _event.key == '.' &&
        (this[field][index].value == null || this[field][index].value == 0) &&
        field != 'call_no_of_shares'
      ) {
        this[field][index].value = 0.0;
        setTimeout(() => {
          _event.target.setSelectionRange(2, 2);
        }, 0);
      } else if (
        _event.key == '.' &&
        (length == selectionEnd || (selectionEnd >= length && selectionStart == 0)) &&
        selectionStart == 0 &&
        field != 'call_no_of_shares'
      ) {
        this[field][index].value = 0.0;
        setTimeout(() => {
          _event.target.setSelectionRange(2, 2);
        }, 0);
      } else if (
        _event.key == '.' &&
        lengthBeforeDecimal == selectionEnd &&
        selectionStart == 0 &&
        field != 'call_no_of_shares'
      ) {
        _event.target.setSelectionRange(lengthBeforeDecimal + 1, lengthBeforeDecimal + 1);
      }
    }
  }

  onAddDecimals(arrStr: string, i: number): void {
    /**
     * take a copy value
     * remove comma
     * add parseFloat
     * add commas
     */
    if (this[arrStr][i]['value']) {
      let value = this[arrStr][i]['value'];
      value = parseFloat(value.replace(/,/g, '')).toFixed(2);
      this[arrStr][i]['value'] = this.commaSeperatorPipe.transform(value, this.getMaxLengthByFieldName(arrStr));
    }
  }

  private getMaxLengthByFieldName(key: string): number {
    switch (key) {
      case 'put_stock_price':
        return 9;
      case 'call_stock_price':
        return 9;
      case 'put_strike_price':
        return 9;
      case 'call_strike_price':
        return 9;
      case 'put_premium':
        return 7;
      case 'call_option_premium':
        return 7;
      case 'call_no_of_shares':
        return 6;
      default:
        break;
    }
  }

  /**
   * This method handles the following list
   * 1) Value should be thousand seperator
   * 2) Value should have decimal
   * 3) Should execute the formula process
   * @param header_id
   */
  private async onValueProcess(
    header_id: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    field?: any,
    index?: number,
    isPut?: boolean,
    isProgrammaticTrigger?: boolean,
  ): Promise<void> {
    this.onExecuteFormula(
      header_id,
      this.ignoreComma(this.put_stock_price[index].value),
      this.ignoreComma(this.call_stock_price[index].value),
      this.ignoreComma(this.put_strike_price[index].value),
      this.ignoreComma(this.call_strike_price[index].value),
      this.ignoreComma(this.put_premium[index].value),
      this.ignoreComma(this.call_option_premium[index].value),
      this.put_dte[index].expiration,
      this.call_expiration[index].expiration,
      this.ignoreComma(this.call_no_of_shares[index].value),
    );

    if (!isProgrammaticTrigger) {
      await this.updateLiveDataSubscription(header_id, index, isPut);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private ignoreComma(value: any): number {
    return value !== null && value !== '' ? value.toString().replace(/,/g, '') : '';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onKeyDown(_ev, field?: any, index?: number, interval?: string, max?: string): void {
    _ev.stopPropagation();

    clearTimeout(this.typingTimer);
    if (interval) {
      this.onArrowKeyPress(_ev, field, index, interval, max);
    }
  }

  private onExecuteFormula(
    header_id: number,
    p_stock_price: number,
    c_stock_price: number,
    p_strike_price: number,
    c_strike_price: number,
    p_premium: number,
    c_premium: number,
    p_expiration,
    c_expiration,
    c_no_of_share,
  ): void {
    const dIndex = this.put_strike_price.findIndex((dd) => dd.header_id === header_id);
    this.put_option_to_trade[dIndex].value = calc.getSellPutsOptionToTrade(
      this.parseFloatValue(p_strike_price),
      this.buyingPower,
      this.maxPosition,
    );
    this.put_option_to_trade[dIndex].need_to_buy = calc.getNeedToBuyStocks(
      this.parseFloatValue(p_strike_price),
      this.buyingPower,
      this.maxPosition,
    );
    this.put_dte[dIndex].dte = calc.getSellPutsDTE(p_expiration);
    this.put_premium[dIndex].min = calc.getSellPutsMinOptionPremium(
      this.parseFloatValue(p_strike_price),
      this.buyingPower,
      this.maxPosition,
      p_expiration,
    );
    this.put_premium[dIndex].max = calc.getSellPutMaxOptionPremium(
      this.parseFloatValue(p_strike_price),
      this.buyingPower,
      this.maxPosition,
      p_expiration,
    );
    this.drop[dIndex].value = calc.getDrop(this.parseFloatValue(p_strike_price), this.parseFloatValue(p_stock_price));
    this.put_premium_per_day[dIndex].premium_collected = calc.getSellPutsPremiumCollected(
      this.parseFloatValue(p_strike_price),
      this.buyingPower,
      this.maxPosition,
      this.parseFloatValue(p_premium),
    );
    this.put_premium_per_day[dIndex].premium_per_day = calc.getSellPutsPremiumPerDay(
      this.parseFloatValue(p_premium),
      this.buyingPower,
      this.maxPosition,
      this.parseFloatValue(p_strike_price),
      p_expiration,
    );
    this.put_premium_annualarized[dIndex].value = Math.round(
      calc.getSellPutsPremiumAnnualarized(
        this.parseFloatValue(p_strike_price),
        p_expiration,
        this.buyingPower,
        this.maxPosition,
        this.parseFloatValue(p_premium),
      ),
    );
    this.put_profit[dIndex].taking = calc.getSellPutsMaxProfitTaker(this.parseFloatValue(p_premium));
    this.put_profit[dIndex].value = calc.getSellPutsMaxProfitValue(
      calc.getSellPutsOptionToTrade(this.parseFloatValue(p_strike_price), this.buyingPower, this.maxPosition),
      p_premium,
      calc.getSellPutsMaxProfitTaker(this.parseFloatValue(p_premium)),
    );
    this.put_min_premiums[dIndex] = this.calculatePremiums(this.parseFloatValue(p_strike_price), p_expiration, true);

    this.call_option_to_trade[dIndex].value = calc.getSellCallsOptionToTrade(c_no_of_share);
    this.call_option_to_trade[dIndex].position_value = calc.getPositionValue(c_stock_price, c_no_of_share);
    this.call_expiration[dIndex].dte = calc.getSellCallsDTE(c_expiration);
    this.call_option_premium[dIndex].min = calc.getSellCallsMinOptionPremium(c_stock_price, c_expiration);
    this.call_premium_per_day[dIndex].premium_collected = calc.getSellCallsPremiumCollected(c_premium, c_no_of_share);
    this.call_profit[dIndex].taking = calc.getSellCallsMaxProfitTaker(c_premium);
    this.call_profit[dIndex].value = calc.getSellCallsMaxProfitValue(
      calc.getSellCallsOptionToTrade(c_no_of_share),
      c_premium,
      calc.getSellCallsMaxProfitTaker(this.parseFloatValue(c_premium)),
    );

    this.rise[dIndex].value = calc.getRise(c_strike_price, c_stock_price);
    this.call_premium_per_day[dIndex].premium_per_day = calc.getSellCallsPremiumPerDay(
      c_premium,
      c_no_of_share,
      c_expiration,
    );
    this.call_premium_annularized[dIndex].value = c_expiration
      ? Math.round(calc.getSellCallsOptionPremiumAnnularized(c_stock_price, c_premium, c_no_of_share, c_expiration))
      : null;
    this.call_stock_gain[dIndex].excercised = calc.getSellCallsStockGain(c_strike_price, c_stock_price, c_no_of_share);
    this.call_stock_gain[dIndex].holding = calc.getSellCallsStockGainHoldingPeriod(c_strike_price, c_stock_price);
    this.call_total_gain[dIndex].value = calc.getSellCallsTotalGain(
      c_premium,
      c_no_of_share,
      c_strike_price,
      c_stock_price,
    );
    this.call_max_profit[dIndex].taking = calc.getSellCallsMaxProfitTaker2(c_stock_price, c_premium, c_strike_price);
    this.call_max_profit[dIndex].value = calc.getSellCallsMaxProfitValue2(
      c_premium,
      c_no_of_share,
      c_strike_price,
      c_stock_price,
    );
    this.call_min_premiums[dIndex] = this.calculatePremiums(this.parseFloatValue(c_stock_price), c_expiration, false);
  }

  isFinit(val): boolean {
    return isFinite(val);
  }

  isNaNValue(value): boolean {
    return isNaN(value);
  }

  private convertToUI(header_id: number): void {
    this.stocks = [];
    this.put_headers = [];
    this.put_dte = [];
    this.put_stock_price = [];
    this.put_strike_price = [];
    this.put_premium = [];
    this.put_option_to_trade = [];
    this.drop = [];
    this.put_premium_per_day = [];
    this.put_premium_annualarized = [];
    this.put_profit = [];
    this.put_min_premiums = [];

    // call
    this.call_expiration = [];
    this.call_max_profit = [];
    this.call_no_of_shares = [];
    this.call_option_premium = [];
    this.call_option_to_trade = [];
    this.call_premium_annularized = [];
    this.call_profit = [];
    this.call_premium_per_day = [];
    this.call_total_gain = [];
    this.call_symbol = [];
    this.rise = [];
    this.call_strike_price = [];
    this.call_stock_gain = [];
    this.call_stock_price = [];
    this.call_min_premiums = [];

    for (let index = 0; index < this.noOfColumns; index++) {
      const d = this.data.find((s) => s.header_id === index + 1);
      if (d) {
        // there is stock
        this.stocks.push({ headerId: d.header_id });
        this.put_headers.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          value: d.put_symbol === 'null' ? null : d.put_symbol,
        });
        this.put_stock_price.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          value:
            d.put_stock_price !== '' && d.put_stock_price !== null
              ? this.commaSeperatorPipe.transform(
                  this.parseFloatValue(d.put_stock_price),
                  this.getMaxLengthByFieldName('put_stock_price'),
                )
              : '',
        });
        this.put_strike_price.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          value:
            d.put_strike_price !== '' && d.put_strike_price !== null
              ? this.commaSeperatorPipe.transform(
                  this.parseFloatValue(d.put_strike_price),
                  this.getMaxLengthByFieldName('put_strike_price'),
                )
              : '',
        });
        this.put_option_to_trade.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          value:
            d.put_strike_price !== null
              ? calc.getSellPutsOptionToTrade(
                  this.parseFloatValue(d.put_strike_price),
                  this.buyingPower,
                  this.maxPosition,
                )
              : null,
          need_to_buy: calc.getNeedToBuyStocks(
            this.parseFloatValue(d.put_strike_price),
            this.buyingPower,
            this.maxPosition,
          ),
        });

        const s_date = convertToEasternTime(this.s_date);
        const put_expiration = convertToEasternTime(d.put_expiration);
        const call_expiration = convertToEasternTime(d.call_expiration);

        if (s_date.isBefore(put_expiration) || s_date.isSame(put_expiration)) {
          this.put_dte.push({
            header_id: d.header_id,
            name: 'stock' + d.header_id,
            expiration: d.put_expiration ? put_expiration.format('YYYY-MM-DD') : null,
            dte: calc.getSellPutsDTE(d.put_expiration),
          });
        } else {
          this.put_dte.push({ header_id: d.header_id, name: 'stock' + d.header_id, dte: null, expiration: null });
        }
        this.put_premium.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          value:
            d.put_option_premium !== '' && d.put_option_premium !== null
              ? this.commaSeperatorPipe.transform(
                  this.parseFloatValue(d.put_option_premium),
                  this.getMaxLengthByFieldName('put_premium'),
                )
              : '',
          min: calc.getSellPutsMinOptionPremium(
            this.parseFloatValue(d.put_strike_price),
            this.buyingPower,
            this.maxPosition,
            d.put_expiration,
          ),
          max: calc.getSellPutMaxOptionPremium(
            this.parseFloatValue(d.put_strike_price),
            this.buyingPower,
            this.maxPosition,
            d.put_expiration,
          ),
        });
        this.drop.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          value: calc.getDrop(this.parseFloatValue(d.put_strike_price), this.parseFloatValue(d.put_stock_price)),
        });
        this.put_premium_per_day.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          premium_collected: calc.getSellPutsPremiumCollected(
            this.parseFloatValue(d.put_strike_price),
            this.buyingPower,
            this.maxPosition,
            this.parseFloatValue(d.put_option_premium),
          ),
          premium_per_day: calc.getSellPutsPremiumPerDay(
            this.parseFloatValue(d.put_option_premium),
            this.buyingPower,
            this.maxPosition,
            this.parseFloatValue(d.put_strike_price),
            d.put_expiration,
          ),
        });
        this.put_premium_annualarized.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          value: Math.round(
            calc.getSellPutsPremiumAnnualarized(
              this.parseFloatValue(d.put_strike_price),
              d.put_expiration,
              this.buyingPower,
              this.maxPosition,
              this.parseFloatValue(d.put_option_premium),
            ),
          ),
        });

        this.put_profit.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          taking: calc.getSellPutsMaxProfitTaker(this.parseFloatValue(d.put_option_premium)),
          value: calc.getSellPutsMaxProfitValue(
            d.put_strike_price !== null
              ? calc.getSellPutsOptionToTrade(
                  this.parseFloatValue(d.put_strike_price),
                  this.buyingPower,
                  this.maxPosition,
                )
              : null,
            d.put_option_premium !== '' && d.put_option_premium !== null ? d.put_option_premium : '',
            calc.getSellPutsMaxProfitTaker(this.parseFloatValue(d.put_option_premium)),
          ),
        });
        this.put_min_premiums.push(
          this.calculatePremiums(
            this.parseFloatValue(d.put_strike_price),
            convertToEasternTime(d.put_expiration)?.format(MomentDateTimeFormats.ServerDate),
            true,
          ),
        );

        // call
        this.call_symbol.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          value: d.call_symbol === 'null' ? null : d.call_symbol,
        });
        this.call_stock_price.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          value:
            d.call_stock_price !== '' && d.call_stock_price !== null
              ? this.commaSeperatorPipe.transform(
                  this.parseFloatValue(d.call_stock_price),
                  this.getMaxLengthByFieldName('call_stock_price'),
                )
              : '',
        });
        this.call_strike_price.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          value:
            d.call_strike_price !== '' && d.call_strike_price !== null
              ? this.commaSeperatorPipe.transform(
                  this.parseFloatValue(d.call_strike_price),
                  this.getMaxLengthByFieldName('call_strike_price'),
                )
              : '',
        });
        if (s_date.isBefore(call_expiration) || s_date.isSame(call_expiration)) {
          this.call_expiration.push({
            header_id: d.header_id,
            name: 'stock' + d.header_id,
            expiration: d.call_expiration ? call_expiration.format('YYYY-MM-DD') : null,
            dte: calc.getSellCallsDTE(d.call_expiration),
          });
        } else {
          this.call_expiration.push({
            header_id: d.header_id,
            name: 'stock' + d.header_id,
            dte: null,
            expiration: null,
          });
        }
        this.call_no_of_shares.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          value:
            d.call_no_of_shares !== '' && d.call_no_of_shares !== null
              ? this.commaSeperatorPipe.transform(
                  d.call_no_of_shares,
                  this.getMaxLengthByFieldName('call_no_of_shares'),
                  true,
                )
              : '',
        });

        this.call_option_premium.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          value:
            d.call_option_premium !== '' && d.call_option_premium !== null
              ? String(this.parseFloatValue(d.call_option_premium))
              : '',
          min: calc.getSellCallsMinOptionPremium(this.parseFloatValue(d.call_stock_price), d.call_expiration),
        });
        this.call_option_to_trade.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          value: calc.getSellCallsOptionToTrade(d.call_no_of_shares),
          position_value: calc.getPositionValue(d.call_stock_price, d.call_no_of_shares),
        });

        this.call_profit.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          taking: calc.getSellCallsMaxProfitTaker(d.call_option_premium),
          value: calc.getSellCallsMaxProfitValue(
            calc.getSellCallsOptionToTrade(d.call_no_of_shares),
            d.call_option_premium,
            calc.getSellCallsMaxProfitTaker(this.parseFloatValue(d.call_option_premium)),
          ),
        });
        this.call_premium_per_day.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          premium_collected: calc.getSellCallsPremiumCollected(d.call_option_premium, d.call_no_of_shares),
          premium_per_day: calc.getSellCallsPremiumPerDay(
            d.call_option_premium,
            d.call_no_of_shares,
            d.call_expiration,
          ),
        });
        this.rise.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          value: calc.getRise(d.call_strike_price, d.call_stock_price),
        });
        this.call_premium_annularized.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          value: d.call_expiration
            ? Math.round(
                calc.getSellCallsOptionPremiumAnnularized(
                  d.call_stock_price,
                  d.call_option_premium,
                  d.call_no_of_shares,
                  d.call_expiration,
                ),
              )
            : null,
        });
        this.call_stock_gain.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          excercised: calc.getSellCallsStockGain(d.call_strike_price, d.call_stock_price, d.call_no_of_shares),
          holding: calc.getSellCallsStockGainHoldingPeriod(d.call_strike_price, d.call_stock_price),
        });
        this.call_total_gain.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          value: calc.getSellCallsTotalGain(
            d.call_option_premium,
            d.call_no_of_shares,
            d.call_strike_price,
            d.call_stock_price,
          ),
        });
        this.call_max_profit.push({
          header_id: d.header_id,
          name: 'stock' + d.header_id,
          taking: calc.getSellCallsMaxProfitTaker2(d.call_stock_price, d.call_option_premium, d.call_strike_price),
          value: calc.getSellCallsMaxProfitValue2(
            d.call_option_premium,
            d.call_no_of_shares,
            d.call_strike_price,
            d.call_stock_price,
          ),
        });
        this.call_min_premiums.push(
          this.calculatePremiums(
            this.parseFloatValue(d.call_stock_price),
            convertToEasternTime(d.call_expiration)?.format(MomentDateTimeFormats.ServerDate),
            false,
          ),
        );
      } else {
        const id = Math.max(...this.stocks.map((d) => d.headerId));
        let maxHeaderId = id > 0 ? id + 1 : 1;

        // set empty column
        this.stocks.push({ headerId: maxHeaderId });
        this.put_headers.push({ header_id: maxHeaderId, name: 'stock' + maxHeaderId, value: null });
        this.put_stock_price.push({ header_id: maxHeaderId, name: 'stock' + maxHeaderId, value: '' });
        this.put_strike_price.push({ header_id: maxHeaderId, name: 'stock' + maxHeaderId, value: '' });
        this.put_option_to_trade.push({
          header_id: maxHeaderId,
          name: 'stock' + maxHeaderId,
          value: null,
          need_to_buy: null,
        });
        this.put_dte.push({ header_id: maxHeaderId, name: 'stock' + maxHeaderId, expiration: null, dte: null });
        this.put_premium.push({ header_id: maxHeaderId, name: 'stock' + maxHeaderId, value: '', min: null, max: null });
        this.drop.push({ header_id: maxHeaderId, name: 'stock' + maxHeaderId, value: null });
        this.put_premium_per_day.push({
          header_id: maxHeaderId,
          name: 'stock' + maxHeaderId,
          premium_collected: null,
          premium_per_day: null,
        });
        this.put_premium_annualarized.push({ header_id: maxHeaderId, name: 'stock' + maxHeaderId, value: null });
        this.put_profit.push({ header_id: maxHeaderId, name: 'stock' + maxHeaderId, taking: null, value: null });

        // call
        this.call_symbol.push({ header_id: maxHeaderId, name: 'stock' + maxHeaderId, value: null });
        this.call_stock_price.push({ header_id: maxHeaderId, name: 'stock' + maxHeaderId, value: '' });
        this.call_no_of_shares.push({ header_id: maxHeaderId, name: 'stock' + maxHeaderId, value: '' });
        this.call_strike_price.push({ header_id: maxHeaderId, name: 'stock' + maxHeaderId, value: '' });
        this.call_option_premium.push({ header_id: maxHeaderId, name: 'stock' + maxHeaderId, value: '', min: null });
        this.call_expiration.push({ header_id: maxHeaderId, name: 'stock' + maxHeaderId, dte: null, expiration: null });
        this.call_option_to_trade.push({
          header_id: maxHeaderId,
          name: 'stock' + maxHeaderId,
          value: null,
          position_value: null,
        });
        this.rise.push({ header_id: maxHeaderId, name: 'stock' + maxHeaderId, value: null });
        this.call_premium_per_day.push({
          header_id: maxHeaderId,
          name: 'stock' + maxHeaderId,
          premium_collected: null,
          premium_per_day: null,
        });
        this.call_premium_annularized.push({ header_id: maxHeaderId, name: 'stock' + maxHeaderId, value: null });
        this.call_profit.push({ header_id: maxHeaderId, name: 'stock' + maxHeaderId, taking: null, value: null });
        this.call_total_gain.push({ header_id: maxHeaderId, name: 'stock' + maxHeaderId, value: null });
        this.call_stock_gain.push({
          header_id: maxHeaderId,
          name: 'stock' + maxHeaderId,
          excercised: null,
          holding: null,
        });
        this.call_max_profit.push({ header_id: maxHeaderId, name: 'stock' + maxHeaderId, taking: null, value: null });
        maxHeaderId++;
      }
      if (header_id && index < this.noOfColumns) {
        setTimeout(() => {
          if (document.getElementById('iwheel-calc')) {
            // done by Jainam Mehta, do not remove it
            document.getElementById('iwheel-calc').scrollTo((header_id - 1) * 187, 0);
          }
        }, 1000);
      }
    }

    setTimeout(() => {
      this.call_expiration.forEach((d, index) => {
        if (document.getElementById('tabIndexs1' + index)) {
          document.getElementById('tabIndexs1' + index).tabIndex = Number('1' + index);
        }
        if (document.getElementById('tabIndexs2' + index)) {
          document.getElementById('tabIndexs2' + index).tabIndex = Number('1' + index);
        }
      });
    }, 1000);
  }

  private parseFloatValue(value: number): number | string {
    return value !== null ? parseFloat(value.toString()).toFixed(2) : null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkForNumberExist(value: any): number {
    return isFinite(value) ? value : 0;
  }

  onClearPutOrCall(id: number, is_put: boolean): void {
    // clear inputs
    const headerindex = this.put_headers.findIndex((h) => h.header_id === id);
    if (headerindex >= 0) {
      const stock = this.data.find((d) => d.header_id === id);
      if (is_put) {
        this.put_headers[headerindex].value = '';
        this.put_dte[headerindex].expiration = '';
        this.put_dte[headerindex].dte = null;
        this.put_stock_price[headerindex].value = '';
        this.put_strike_price[headerindex].value = '';
        this.put_premium[headerindex].value = '';
        this.put_premium[headerindex].min = null;
        this.put_premium[headerindex].max = null;
        this.put_option_to_trade[headerindex].value = '';
        this.put_option_to_trade[headerindex].need_to_buy = null;
        this.drop[headerindex].value = '';
        this.put_premium_per_day[headerindex].premium_collected = null;
        this.put_premium_per_day[headerindex].premium_per_day = null;
        this.put_profit[headerindex].taking = null;
        this.put_profit[headerindex].value = '';
        this.put_premium_annualarized[headerindex].value = '';
        if (stock) {
          stock.put_expiration = null;
          stock.put_option_premium = '';
          stock.put_stock_price = '';
          stock.put_strike_price = '';
          stock.put_symbol = '';
        }
      } else {
        this.call_symbol[headerindex].value = '';
        this.call_stock_price[headerindex].value = '';
        this.call_no_of_shares[headerindex].value = '';
        this.call_strike_price[headerindex].value = '';
        this.call_option_premium[headerindex].value = '';
        this.call_option_premium[headerindex].min = null;
        this.call_expiration[headerindex].dte = null;
        this.call_expiration[headerindex].expiration = '';
        this.call_option_to_trade[headerindex].value = '';
        this.call_option_to_trade[headerindex].position_value = null;
        this.rise[headerindex].value = '';
        this.call_premium_per_day[headerindex].premium_collected = null;
        this.call_premium_per_day[headerindex].premium_per_day = null;
        this.call_premium_annularized[headerindex].value = '';
        this.call_profit[headerindex].value = '';
        this.call_profit[headerindex].taking = null;
        this.call_total_gain[headerindex].value = '';
        this.call_stock_gain[headerindex].excercised = null;
        this.call_stock_gain[headerindex].holding = '';
        this.call_max_profit[headerindex].value = '';
        this.call_max_profit[headerindex].taking = null;
        if (stock) {
          stock.call_expiration = null;
          stock.call_no_of_shares = '';
          stock.call_option_premium = '';
          stock.call_stock_price = '';
          stock.call_strike_price = '';
          stock.call_symbol = '';
        }
      }
      if (
        stock &&
        stock.put_expiration === null &&
        stock.put_option_premium === null &&
        stock.put_stock_price === '' &&
        stock.put_strike_price === '' &&
        stock.put_symbol === null &&
        stock.call_expiration === null &&
        stock.call_no_of_shares === '' &&
        stock.call_option_premium === '' &&
        stock.call_stock_price === '' &&
        stock.call_strike_price === '' &&
        stock.call_symbol === null
      ) {
        this.data = this.data.filter((d) => d.header_id !== id);
      }
      if (stock && stock.id) {
        const payload = {
          id: stock.id,
          user_id: this.userId,
          is_put: is_put,
          is_clear: true,
        };
        // send cleaar signal to backend
        this.wheelCalculatorService.deleteWheelCalculatorStocksByCallOrPut(payload).subscribe(() => {});
      }

      if (is_put && headerindex === this.putSubscription?.headerIndex) {
        this.unsubscribePutLiveData();
      } else if (!is_put && headerindex === this.callSubscription?.headerIndex) {
        this.unsubscribeCallLiveData();
      }
    }
  }

  async onDelete(id: number): Promise<void> {
    if (this.put_headers.length > 1) {
      if (this.noOfColumns > 1) {
        this.noOfColumns--;
      }
      const stock = this.data.find((d) => d.header_id === id);
      await this.convertToDB();
      this.data = this.data.filter((s) => (s.id == null && s.header_id !== id) || s.id !== null);
      const idd = stock ? [stock.id] : [];
      this.wheelCalculatorService
        .deleteWheelCalculatorStocksByIds({ ids: idd, user_id: this.userId, is_clear: false, data: this.data })
        .subscribe(() => {
          this.data = [];
          this.loadData();

          if (this.putSubscription && id < this.putSubscription.headerId) {
            this.putSubscription = {
              ...this.putSubscription,
              headerIndex: this.putSubscription.headerIndex - 1,
              headerId: this.putSubscription.headerId - 1,
            };
          }

          if (this.callSubscription && id < this.callSubscription.headerId) {
            this.callSubscription = {
              ...this.callSubscription,
              headerIndex: this.callSubscription.headerIndex - 1,
              headerId: this.callSubscription.headerId - 1,
            };
          }
        });

      if (id === this.putSubscription?.headerId) {
        this.unsubscribePutLiveData();
      }

      if (id === this.callSubscription?.headerId) {
        this.unsubscribeCallLiveData();
      }
    }
  }

  onAdd(index: number): void {
    if (this.put_headers.length < 20) {
      // find last header id
      const newHeaderId = Math.max(...this.put_headers.map((h) => h.header_id)) + 1;
      this.stocks.splice(index + 1, 0, { headerId: newHeaderId });
      this.put_headers.splice(index + 1, 0, { header_id: newHeaderId, name: 'stock' + newHeaderId, value: null });
      this.put_stock_price.splice(index + 1, 0, { header_id: newHeaderId, name: 'stock' + newHeaderId, value: '' });
      this.put_strike_price.splice(index + 1, 0, { header_id: newHeaderId, name: 'stock' + newHeaderId, value: '' });
      this.put_option_to_trade.splice(index + 1, 0, {
        header_id: newHeaderId,
        name: 'stock' + newHeaderId,
        value: null,
        need_to_buy: null,
      });
      this.put_dte.splice(index + 1, 0, {
        header_id: newHeaderId,
        name: 'stock' + newHeaderId,
        expiration: null,
        dte: null,
      });
      this.put_premium.splice(index + 1, 0, {
        header_id: newHeaderId,
        name: 'stock' + newHeaderId,
        value: '',
        min: null,
        max: null,
      });
      this.drop.splice(index + 1, 0, { header_id: newHeaderId, name: 'stock' + newHeaderId, value: null });
      this.put_premium_per_day.splice(index + 1, 0, {
        header_id: newHeaderId,
        name: 'stock' + newHeaderId,
        premium_collected: null,
        premium_per_day: null,
      });
      this.put_premium_annualarized.splice(index + 1, 0, {
        header_id: newHeaderId,
        name: 'stock' + newHeaderId,
        value: null,
      });
      this.put_profit.splice(index + 1, 0, {
        header_id: newHeaderId,
        name: 'stock' + newHeaderId,
        value: null,
        taking: null,
      });

      this.call_symbol.splice(index + 1, 0, { header_id: newHeaderId, name: 'stock' + newHeaderId, value: null });
      this.call_stock_price.splice(index + 1, 0, { header_id: newHeaderId, name: 'stock' + newHeaderId, value: '' });
      this.call_no_of_shares.splice(index + 1, 0, { header_id: newHeaderId, name: 'stock' + newHeaderId, value: '' });
      this.call_strike_price.splice(index + 1, 0, { header_id: newHeaderId, name: 'stock' + newHeaderId, value: '' });
      this.call_option_premium.splice(index + 1, 0, {
        header_id: newHeaderId,
        name: 'stock' + newHeaderId,
        min: null,
        value: '',
      });
      this.call_expiration.splice(index + 1, 0, {
        header_id: newHeaderId,
        name: 'stock' + newHeaderId,
        dte: null,
        expiration: null,
      });
      this.call_option_to_trade.splice(index + 1, 0, {
        header_id: newHeaderId,
        name: 'stock' + newHeaderId,
        position_value: null,
        value: null,
      });
      this.rise.splice(index + 1, 0, { header_id: newHeaderId, name: 'stock' + newHeaderId, value: null });
      this.call_premium_per_day.splice(index + 1, 0, {
        header_id: newHeaderId,
        name: 'stock' + newHeaderId,
        premium_collected: null,
        premium_per_day: null,
      });
      this.call_premium_annularized.splice(index + 1, 0, {
        header_id: newHeaderId,
        name: 'stock' + newHeaderId,
        value: null,
      });
      this.call_profit.splice(index + 1, 0, {
        header_id: newHeaderId,
        name: 'stock' + newHeaderId,
        taking: null,
        value: null,
      });
      this.call_total_gain.splice(index + 1, 0, { header_id: newHeaderId, name: 'stock' + newHeaderId, value: null });
      this.call_stock_gain.splice(index + 1, 0, {
        header_id: newHeaderId,
        name: 'stock' + newHeaderId,
        excercised: null,
        holding: null,
      });
      this.call_max_profit.splice(index + 1, 0, {
        header_id: newHeaderId,
        name: 'stock' + newHeaderId,
        taking: null,
        value: null,
      });
      this.noOfColumns += 1;
      this.updateColumn();
      setTimeout(() => {
        if (document.getElementById('tabIndexs1' + (index + 1))) {
          document.getElementById('tabIndexs1' + (index + 1)).tabIndex = Number('1' + (index + 1));
        }
        if (document.getElementById('tabIndexs2' + (index + 1))) {
          document.getElementById('tabIndexs2' + (index + 1)).tabIndex = Number('1' + (index + 1));
        }
      }, 1000);
    } else {
      // show alert popup exceeding limit
      this.maxColumn();
    }

    setTimeout(() => {
      if (document.getElementById('iwheel-calc')) {
        document.getElementById('iwheel-calc').scrollTo(10000, 0);
      }
    }, 800);
  }

  maxColumn(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['max-popup', 'modals'];
    dialogConfig.disableClose = true;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const dialogRef = this.dialog.open(MaxPopupComponent, dialogConfig);
    document.getElementsByClassName('modals')[0].scrollTo(0, 0);
  }

  private async updateColumn(): Promise<void> {
    this.userSetting['wColumnSize'] = this.noOfColumns;
    localStorage.setItem('user', JSON.stringify(this.userSetting));
    await this.wheelCalculatorService
      .updateUserSetting({
        userId: this.userId,
        buyingPower: this.buyingPower,
        noOfColumns: this.noOfColumns,
        maxPosition: this.maxPosition,
      })
      .toPromise();
    this.onUpdate();
  }

  onBPValidation(evt: KeyboardEvent): void {
    if (
      evt &&
      (evt.charCode < 46 ||
        evt.charCode > 57 ||
        ((evt.charCode < 46 || evt.charCode > 57) && (evt.target['value'] !== '' && Number(evt.target['value'])) <= 0))
    ) {
      evt.preventDefault();
    }
  }

  onPValidation(evt: KeyboardEvent): void {
    if (
      evt &&
      (evt.key === '.' || evt.key === '-' || evt.key === '+' || evt.key === 'e') &&
      !(evt.target['value'] > 0 && evt.target['value'] < 11)
    ) {
      evt.preventDefault();
    }
  }

  onCustomPValidation(evt: KeyboardEvent): void {
    if (evt && (evt.key === '-' || evt.key === '+' || evt.key === 'e')) {
      evt.preventDefault();
    }
  }

  onColumnValidation(evt: KeyboardEvent): void {
    if (
      evt &&
      (evt.key === '.' || evt.key === '-' || evt.key === 'e') &&
      !(evt.target['value'] > 0 && evt.target['value'] < 11)
    ) {
      evt.preventDefault();
    }
  }

  private convertToDB(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise<void>((resolve, reject) => {
      for (let index = 0; index <= this.noOfColumns; index++) {
        const put_symbol = this.put_headers.find((h) => h.header_id === this.put_headers[index].header_id).value;
        const put_stock_price = this.put_stock_price.find(
          (h) => h.header_id === this.put_stock_price[index].header_id,
        ).value;
        const put_strike_price = this.put_strike_price.find(
          (h) => h.header_id === this.put_strike_price[index].header_id,
        ).value;
        const option_premium = this.put_premium.find((h) => h.header_id === this.put_premium[index].header_id).value;
        const pE = this.put_dte.find((h) => h.header_id === this.put_dte[index].header_id).expiration;
        const put_expiration = pE ? convertToEasternTime(pE).unix() : null;
        const call_symbo = this.call_symbol.find((h) => h.header_id === this.call_symbol[index].header_id).value;
        const call_stock_pric = this.call_stock_price.find(
          (h) => h.header_id === this.call_stock_price[index].header_id,
        ).value;
        const call_strike_pric = this.call_strike_price.find(
          (h) => h.header_id === this.call_strike_price[index].header_id,
        ).value;
        const cE = this.call_expiration.find((h) => h.header_id === this.call_expiration[index].header_id).expiration;
        const call_expiratio = cE ? convertToEasternTime(cE).unix() : null;
        const call_option_premiu = this.call_option_premium.find(
          (h) => h.header_id === this.call_option_premium[index].header_id,
        ).value;
        const call_no_of_share = this.call_no_of_shares.find(
          (h) => h.header_id === this.call_no_of_shares[index].header_id,
        ).value;

        const dataIndex = this.data.findIndex((d) => d.header_id === this.put_headers[index].header_id);
        if (dataIndex >= 0) {
          // update
          if (
            put_symbol !== null ||
            put_stock_price !== '' ||
            put_strike_price !== '' ||
            put_expiration ||
            option_premium !== null ||
            call_symbo !== null ||
            call_stock_pric !== null ||
            call_strike_pric !== null ||
            call_option_premiu !== null ||
            call_expiratio ||
            call_no_of_share !== null
          ) {
            this.data[dataIndex].put_symbol = put_symbol ? String.raw`${put_symbol}`.toUpperCase() : null;
            this.data[dataIndex].put_stock_price = this.ignoreComma(put_stock_price);
            this.data[dataIndex].put_strike_price = this.ignoreComma(put_strike_price);
            this.data[dataIndex].put_expiration = put_expiration;
            this.data[dataIndex].put_option_premium = this.ignoreComma(option_premium);
            this.data[dataIndex].call_symbol = call_symbo ? call_symbo.toUpperCase() : null;
            this.data[dataIndex].call_stock_price = this.ignoreComma(call_stock_pric);
            this.data[dataIndex].call_strike_price = this.ignoreComma(call_strike_pric);
            this.data[dataIndex].call_option_premium = this.ignoreComma(call_option_premiu);
            this.data[dataIndex].call_expiration = call_expiratio;
            this.data[dataIndex].call_no_of_shares = this.ignoreComma(call_no_of_share);
          }
        } else {
          // create
          if (
            (put_symbol !== null && put_symbol !== '') ||
            (put_stock_price !== null && put_stock_price !== '') ||
            (option_premium !== null && option_premium !== '') ||
            (put_strike_price !== null && put_strike_price !== '') ||
            put_expiration !== null ||
            (call_symbo !== null && call_symbo !== '') ||
            (call_stock_pric !== null && call_stock_pric !== '') ||
            (call_no_of_share !== null && call_no_of_share !== '') ||
            (call_strike_pric !== null && call_strike_pric !== '') ||
            (call_option_premiu !== null && call_option_premiu !== '') ||
            call_expiratio !== null
          ) {
            this.data.push(
              new WheelCalculator(
                null,
                this.userId,
                this.put_headers[index].header_id,
                put_symbol ? String.raw`${put_symbol}`.toUpperCase() : null,
                this.ignoreComma(put_stock_price),
                this.ignoreComma(put_strike_price),
                this.ignoreComma(option_premium),
                put_expiration,
                call_symbo ? call_symbo.toUpperCase() : null,
                this.ignoreComma(call_stock_pric),
                this.ignoreComma(call_strike_pric),
                this.ignoreComma(call_option_premiu),
                call_expiratio,
                this.ignoreComma(call_no_of_share),
              ),
            );
          }
        }
        if (index === this.put_headers.length - 1) {
          resolve();
        }
      }
    });
  }

  async onUpdate(): Promise<void> {
    // convert to DB
    await this.convertToDB();
    this.wheelCalculatorService.save(this.data).subscribe(() => {
      this.loadData();
    });
  }

  onArrowKeyPress(event, field, index, interval, max): void {
    max = parseInt(max);
    const charCode = event.which ? event.which : event.keyCode;

    const noCommaNumber = String(this[field][index].value).replace(/,/g, '');

    switch (charCode) {
      case 38: // Uparrow key
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        Number(noCommaNumber) < Number(max)
          ? (this[field][index].value = (Number(noCommaNumber) + parseFloat(interval)).toFixed(2))
          : (this[field][index].value = noCommaNumber);
        break;
      case 40: // Downarrow key
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        Number(noCommaNumber) >= parseFloat(interval)
          ? (this[field][index].value = (Number(noCommaNumber) - parseFloat(interval)).toFixed(2))
          : (this[field][index].value = '0.0');
        break;
    }
  }

  isInput(e): boolean {
    // eslint-disable-next-line
    return /[A-Za-z0-9\-\_\.\/]$/.test(e.key);
  }

  focusNext(i, e): void {
    // disable scroll in conservative/agressive tabs
    this.observableService.isAbletoUpDown.next(false);

    const id = e.srcElement
      ? e.srcElement.id.substr(0, e.srcElement.id.length - 1)
      : e.source._id.substr(0, e.source._id.length - 1);
    let currentIndex = null;

    const index = [
      { input_name: 'put_stock_symbol' },
      { input_name: 'put_stock_price' },
      { input_name: 'tabIndexs1' },
      { input_name: 'put_strike_price' },
      { input_name: 'put_premium' },
      { input_name: 'call_stock_symbol' },
      { input_name: 'call_stock_price' },
      { input_name: 'tabIndexs2' },
      { input_name: 'call_no_of_shares' },
      { input_name: 'call_strike_price' },
      { input_name: 'call_option_premium' },
    ];

    for (let n = 0; n < index.length; n++) {
      // id example: "put_stock_symbol13"
      if (id.includes(index[n].input_name)) {
        currentIndex = n;
      }
    }
    if (currentIndex != null && currentIndex < index.length - 1) {
      setTimeout(() => {
        document.getElementById(index[currentIndex + 1].input_name + i).focus();
      }, 50);
    } else if (currentIndex != null && currentIndex == index.length - 1) {
      const nextColumn = i + 1;
      if (nextColumn < this.noOfColumns) {
        document.getElementById(index[0].input_name + nextColumn).focus();
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showTradeLink(): any {
    const { tradier_flag_ifs, tradier_flag_pxo } = this.tradierFlags || {};
    return tradier_flag_ifs && tradier_flag_pxo;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isValueZero(index, value, isPut): any {
    const arr = [];
    for (let i = 0; i < value.length; i++) {
      if (value[i] === 'premium') {
        if (isPut) {
          const disabled = Number(this.put_premium[index].value) <= 0;
          arr.push(disabled);
        } else {
          const disabled = Number(this.call_option_premium[index].value) <= 0;
          arr.push(disabled);
        }
      }
      if (value[i] === 'strike') {
        if (isPut) {
          const disabled = Number(this.put_strike_price[index].value) <= 0;
          arr.push(disabled);
        } else {
          const disabled = Number(this.call_strike_price[index].value) <= 0;
          arr.push(disabled);
        }
      }
      if (value[i] === 'expiration') {
        if (isPut) {
          const disabled =
            this.put_dte[index].expiration === null ||
            this.put_dte[index].expiration === '' ||
            this.checkIfDateInThePast(this.put_dte[index].expiration);
          arr.push(disabled);
        } else {
          const disabled =
            this.call_expiration[index].expiration === null ||
            this.call_expiration[index].expiration === '' ||
            this.checkIfDateInThePast(this.call_expiration[index].expiration);
          arr.push(disabled);
        }
      }
      if (value[i] === 'ticker') {
        if (isPut) {
          const disabled = this.put_headers[index].value === null || this.put_headers[index].value === '';
          arr.push(disabled);
        } else {
          const disabled = this.call_symbol[index].value === null || this.call_symbol[index].value === '';
          arr.push(disabled);
        }
      }
      if (value[i] === 'share') {
        const disabled =
          this.call_no_of_shares[index].value === null ||
          this.call_no_of_shares[index].value === '' ||
          Number(this.call_no_of_shares[index].value) < 50;
        arr.push(disabled);
      }
    }
    return arr.find((x) => x === true);
  }

  tradeLinkTradingPanelDisable(index, value, isPut): boolean {
    const disableBtn = this.isValueZero(index, value, isPut);
    this.tradeLinkTooltipEnabled = true;
    if (disableBtn) {
      if (isPut) {
        this.tradeLinkTooltip = `Please check that the following fields are completed to activate it:
          \n - Ticker \n - Expiration \n - Premium`;
      } else {
        this.tradeLinkTooltip = `Please check that the following fields are completed to activate it:
          \n - Ticker \n - Expiration \n - Strike Price \n - Premium`;
      }
      return true;
    } else {
      this.tradeLinkTooltip = `Place Order`;
      this.tradeLinkTooltipEnabled = false;
      return false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  expirationDate(date) {
    if (!date)
      return {
        year: '00',
        month: '00',
        day: '00',
      };
    const data = date.split('-');
    return {
      year: data[0].substring(2),
      month: data[1],
      day: data[2],
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  pad(price) {
    if (!price) return '00000000';
    const noCommaNumber = String(price).replace(/,/g, '');
    const number = noCommaNumber.split('.');
    const left = `00000${number[0]}`;
    const right = `${number[1] ? number[1] : ''}000`;
    if (number[0].length > 5) {
      return `${left.slice(5, -1)}${right.substring(0, 3)}`;
    }
    return `${left.substring(number[0].length)}${right.substring(0, 3)}`;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async tradeLinkTradingPanel(index, stock, callOrPut) {
    const quantity = stock.value ? stock.value : 0;

    const strike = callOrPut === 'P' ? this.put_strike_price[index].value : this.call_strike_price[index].value;
    const expiration = callOrPut === 'P' ? this.put_dte[index].expiration : this.call_expiration[index].expiration;
    const symbol =
      callOrPut === 'P' ? this.put_headers[index].value.toUpperCase() : this.call_symbol[index].value.toUpperCase();
    const price = callOrPut === 'P' ? this.put_premium[index].value : this.call_option_premium[index].value;
    const date = this.expirationDate(expiration);
    const optionSymbol = `${symbol}${date.year}${date.month}${date.day}${callOrPut}${this.pad(strike)}`;
    const expirationDate = moment(expiration).format(MomentDateTimeFormats.ReadableDateFullYear);

    this.tradingPanelService.showOrderModal({
      option: {
        underlyingSymbol: symbol,
        symbol: optionSymbol,
        expirationDate,
        strike: Number(strike),
        type: callOrPut === 'P' ? 'put' : 'call',
      },
      orderClass: TradingOrderClass.Option,
      orderType: TradingOrderType.Limit,
      side: TradierOrderSide.SellToOpen,
      quantity,
      price: Number(price),
      duration: TradingOrderDuration.Day,
      onTradingPanelNavigateHandler: null,
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  profitLinkShow(index, isPut) {
    if (isPut) {
      return Number(this.put_premium[index].value) > 0;
    } else {
      return Number(this.call_option_premium[index].value) > 0;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async profitLinkTradingPanel(index, callOrPut, price) {
    const strike = callOrPut === 'P' ? this.put_strike_price[index].value : this.call_strike_price[index].value;
    const expiration = callOrPut === 'P' ? this.put_dte[index].expiration : this.call_expiration[index].expiration;
    const symbol =
      callOrPut === 'P' ? this.put_headers[index].value.toUpperCase() : this.call_symbol[index].value.toUpperCase();

    const date = this.expirationDate(expiration);
    const optionSymbol = `${symbol}${date.year}${date.month}${date.day}${callOrPut}${this.pad(strike)}`;
    const expirationDate = moment(expiration).format(MomentDateTimeFormats.ReadableDateFullYear);

    this.tradingPanelService.showOrderModal({
      option: {
        underlyingSymbol: symbol,
        symbol: optionSymbol,
        expirationDate,
        strike: Number(strike),
        type: callOrPut === 'P' ? 'put' : 'call',
      },
      orderClass: TradingOrderClass.Option,
      orderType: TradingOrderType.Limit,
      side: TradierOrderSide.BuyToClose,
      quantity: null,
      isQtyNote: true,
      price: round(price, 2),
      duration: TradingOrderDuration.GTC,
      onTradingPanelNavigateHandler: null,
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  checkIfDateInThePast(date) {
    return moment(date).isBefore(this.currentDate);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private setActiveCol(headerIndex: number) {
    const rows = document.querySelectorAll('tbody .marker-row');
    if (this.showLiveDataColumn(headerIndex)) {
      rows.forEach((item) => {
        if (item.querySelectorAll('.stock')[headerIndex]) {
          item.querySelectorAll('.stock')[headerIndex].classList.add('active-col');
        }
      });
    } else {
      rows.forEach((item) => {
        if (item.querySelectorAll('.stock')[headerIndex]) {
          item.querySelectorAll('.stock')[headerIndex].classList.remove('active-col');
        }
      });
    }
  }

  public canSubscribeToLiveData(id: number, isPut: boolean): boolean {
    const headerIndex = this.put_headers.findIndex((h) => h.header_id === id);
    const stock = isPut
      ? this.put_dte.find(({ header_id }) => header_id === id)
      : this.call_expiration.find(({ header_id }) => header_id === id);
    let canSubscribe = false;

    if (headerIndex >= 0 && stock) {
      canSubscribe = isPut
        ? !!this.put_headers[headerIndex].value && !!stock.expiration
        : !!this.call_symbol[headerIndex].value && !!stock.expiration;
    }

    return canSubscribe;
  }

  public isLiveDataSubscriptionInProgress(isPut: boolean): boolean {
    return isPut
      ? !!this.putSubscription && !this.putOptionsChain && !this.isPutSubscriptionTimeoutTriggered
      : !!this.callSubscription && !this.callOptionsChain && !this.isCallSubscriptionTimeoutTriggered;
  }

  public getLiveDataTooltip(id: number, isPut: boolean): string {
    const enabledLabel = isPut
      ? `Stock Price. Set real-time or closing price (if the market is closed).
         Premium. Show real-time or last Bid (if the market is closed).`
      : `Premium. Show real-time or last Bid (if the market is closed).`;

    return this.canSubscribeToLiveData(id, isPut)
      ? enabledLabel
      : `Complete the following fields to get market prices: \n - Ticker \n - Expiration`;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  public showLiveDataColumn(headerId: number) {
    return this.putSubscription?.headerId === headerId || this.callSubscription?.headerId === headerId;
  }

  public async subscribeLiveData(id: number, isPut: boolean): Promise<void> {
    if (!this.editionsService.isFeatureAvailable(Features.WheelCalculatorLiveData)) {
      await this.editionsService.redirectToDemoPage(Features.WheelCalculatorLiveData);
      return null;
    }

    this.indexOfActiveCol = id;
    if (!this.canSubscribeToLiveData(id, isPut) || this.isLiveDataSubscriptionInProgress(isPut)) {
      return;
    }

    const headerIndex = isPut
      ? this.put_headers.findIndex((h) => h.header_id === id)
      : this.call_symbol.findIndex((h) => h.header_id === id);

    const existingHeaderIndex = isPut ? this.callSubscription?.headerIndex : this.putSubscription?.headerIndex;

    if (existingHeaderIndex >= 0 && existingHeaderIndex !== headerIndex) {
      this.unsubscribeAllLiveData();
    } else if (isPut) {
      this.putSubscription = null;
      this.putOptionsChain = null;
      this.isPutSubscriptionTimeoutTriggered = false;
      this.put_stock_price[headerIndex].value = '';
    } else {
      this.callSubscription = null;
      this.callOptionsChain = null;
      this.isCallSubscriptionTimeoutTriggered = false;
    }

    const symbol = isPut
      ? this.put_headers[headerIndex].value?.toUpperCase()
      : this.call_symbol[headerIndex].value?.toUpperCase();
    const stock = isPut
      ? this.put_dte.find(({ header_id }) => header_id === id)
      : this.call_expiration.find(({ header_id }) => header_id === id);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const stockPrice: any = isPut ? null : this.ignoreComma(this.call_stock_price[headerIndex].value);
    const expiration = moment(stock.expiration, MomentDateTimeFormats.ServerDate);
    const symbolData = await this.symbolsService.getBySymbol(symbol, ExchangeCountriesCodes.US);

    const subscription = {
      subscriptionId: uuidv4(),
      symbol: { symbol, country_code: ExchangeCountriesCodes.US },
      isValidSymbol: !!symbolData,
      symbolKey: `${symbol}_${stock.expiration}_${isPut ? 'put' : 'call'}`,
      headerId: id,
      headerIndex,
      expiration,
      isPut,
      stockPrice: stockPrice === null || stockPrice === '' ? null : parseFloat(stockPrice),
    };

    if (subscription.isPut) {
      this.streamingService.subscribe(
        subscription.subscriptionId,
        subscription.symbol,
        {},
        this.handleLiveDataStockPriceUpdate.bind(this),
        this.onBeforeLiveDataStockPriceHandlerCallback.bind(this),
      );
    }

    this.liveWheelCalculatorStreamingService.subscribe(
      subscription.subscriptionId,
      subscription.symbolKey,
      this.handleLiveDataOptionChainUpdate.bind(this),
    );

    if (isPut) {
      this.putSubscription = subscription;
    } else {
      this.callSubscription = subscription;
    }

    this.selectDataWindowSymbol(headerIndex, isPut ? 'put' : 'call');
    this.setActiveCol(headerIndex);

    setTimeout(() => {
      const el = document.getElementById(`live-data-column${headerIndex}`);

      if (el) {
        el.scrollIntoView();
      }
    }, 50);

    if (isPut) {
      if (!this.showPutRow) {
        this.showPutRow = true;
      }

      this.putSubscriptionTimeout = setTimeout(() => {
        this.isPutSubscriptionTimeoutTriggered = true;
      }, 15 * 1000);
    } else {
      if (!this.showCallRow) {
        this.showCallRow = true;
      }

      this.callSubscriptionTimeout = setTimeout(() => {
        this.isCallSubscriptionTimeoutTriggered = true;
      }, 15 * 1000);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async updateLiveDataSubscription(header_id: number, index: number, isTargetPut: boolean) {
    for (const subscription of [this.putSubscription, this.callSubscription]) {
      const { headerId, symbol, symbolKey, expiration, stockPrice, isPut } = subscription || {};

      if (header_id !== headerId || isTargetPut !== isPut) {
        continue;
      }

      const newStockPrice = isPut
        ? parseFloat(this.put_stock_price[index].value)
        : parseFloat(this.call_stock_price[index].value);

      const newSymbol = isPut ? this.put_headers[index].value : this.call_symbol[index].value;

      const stock = isPut
        ? this.put_dte.find((dte) => dte.header_id === header_id)
        : this.call_expiration.find((dte) => dte.header_id === header_id);

      if (symbol.symbol !== newSymbol || expiration.format(MomentDateTimeFormats.ServerDate) !== stock.expiration) {
        await this.subscribeLiveData(header_id, isPut);
      } else if (stockPrice !== newStockPrice) {
        subscription.stockPrice = newStockPrice;
        const optionChain = isPut ? this.putOptionsChain : this.callOptionsChain;

        this.handleLiveDataOptionChainUpdate(
          optionChain || {
            symbol: symbolKey,
            nextUpdateAt: null,
            updatedAt: null,
            isTodayHoliday: false,
          },
        );
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  liveDataPutClick(item: IOption) {
    if (!this.putSubscription) {
      return;
    }

    const { headerId, headerIndex } = this.putSubscription;
    const { strike, bid } = item;

    this.put_strike_price[headerIndex].value = strike.toString();
    this.put_premium[headerIndex].value = bid.toString();

    this.onValueProcess(headerId, 'put_strike_price', headerIndex, true, true);
    this.onValueProcess(headerId, 'put_premium', headerIndex, true, true);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  liveDataCallClick(item: IOption) {
    if (!this.callSubscription) {
      return;
    }

    const { headerId, headerIndex } = this.callSubscription;
    const { strike, bid } = item;

    this.call_strike_price[headerIndex].value = strike.toString();
    this.call_option_premium[headerIndex].value = bid.toString();

    this.onValueProcess(headerId, 'call_strike_price', headerIndex, false, true);
    this.onValueProcess(headerId, 'call_option_premium', headerIndex, false, true);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async liveDataPutRetry() {
    if (!this.putSubscription) {
      return;
    }

    const { headerId } = this.putSubscription;
    await this.subscribeLiveData(headerId, true);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async liveDataCallRetry() {
    if (!this.callSubscription) {
      return;
    }

    const { headerId } = this.callSubscription;
    await this.subscribeLiveData(headerId, false);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  public unsubscribeAllLiveData() {
    this.unsubscribePutLiveData();
    this.unsubscribeCallLiveData();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private unsubscribePutLiveData() {
    if (!this.putSubscription) {
      return;
    }

    const { headerIndex } = this.putSubscription;
    this.unsubscribeLiveData(this.putSubscription);

    this.putSubscription = null;
    this.putOptionsChain = null;
    this.isPutSubscriptionTimeoutTriggered = false;

    if (!this.callSubscription) {
      this.setActiveCol(headerIndex);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private unsubscribeCallLiveData() {
    if (!this.callSubscription) {
      return;
    }

    const { headerIndex } = this.callSubscription;
    this.unsubscribeLiveData(this.callSubscription);

    this.callSubscription = null;
    this.callOptionsChain = null;
    this.isCallSubscriptionTimeoutTriggered = false;

    if (!this.putSubscription) {
      this.setActiveCol(headerIndex);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private unsubscribeLiveData(subscription: ILiveSubscription) {
    if (!subscription) {
      return;
    }

    if (subscription.isPut) {
      this.streamingService.unsubscribe(subscription.subscriptionId, subscription.symbol);
    }

    this.liveWheelCalculatorStreamingService.unsubscribe(subscription.subscriptionId, subscription.symbolKey);

    if (subscription.isPut && this.putSubscriptionTimeout) {
      clearTimeout(this.putSubscriptionTimeout);
    } else if (this.callSubscriptionTimeout) {
      clearTimeout(this.callSubscriptionTimeout);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleLiveDataStockPriceUpdate(data: any): void {
    if (this.putSubscription?.symbol?.symbol !== data.symbol) {
      return;
    }

    if (this.putSubscription.stockPrice) {
      return;
    }

    const { symbolKey, headerId, headerIndex } = this.putSubscription;

    this.put_stock_price[headerIndex].value = data.close;
    this.putSubscription.stockPrice = data.close;

    this.onValueProcess(headerId, 'put_stock_price', headerIndex, true, true);
    this.handleLiveDataOptionChainUpdate(
      this.putOptionsChain || {
        symbol: symbolKey,
        nextUpdateAt: null,
        updatedAt: null,
        isTodayHoliday: false,
      },
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async onBeforeLiveDataStockPriceHandlerCallback(data: any, isTodayHoliday: boolean = null): Promise<void> {
    const shouldRun = this.marketTimeService.isBeforeMarketTime() || isTodayHoliday;
    if (!shouldRun || !this.putSubscription) {
      return;
    }

    const { symbol } = this.putSubscription;
    const symbolData = await this.symbolsService.getBySymbol(symbol.symbol, symbol.country_code);
    const historicalData = await this.historicalDataService.get(symbolData.security_id);

    if (historicalData && historicalData.length) {
      const recentClose = historicalData[historicalData.length - 1];
      this.handleLiveDataStockPriceUpdate({
        ...recentClose,
        symbol: symbol.symbol,
      });
    }
  }

  private handleLiveDataOptionChainUpdate(data: IOptionsChain): void {
    let subscription = null;
    if (this.putSubscription?.symbolKey === data.symbol) {
      subscription = this.putSubscription;
      if (!this.isPutUpdateOverlay) {
        this.isPutUpdateOverlay = true;
      }
    } else if (this.callSubscription?.symbolKey === data.symbol) {
      subscription = this.callSubscription;
      if (!this.isCallUpdateOverlay) {
        this.isCallUpdateOverlay = true;
      }
    }

    if (!subscription) {
      return;
    }

    const isTodayHoliday = data?.isTodayHoliday;
    const dataToProcess = data?.options ? data : subscription.preProcessedOptionsChain;

    if (!dataToProcess) {
      return;
    }

    if (!subscription.preProcessedOptionsChain && dataToProcess.options) {
      subscription.preProcessedOptionsChain = dataToProcess;
    }

    if (!dataToProcess) {
      return;
    }

    if (subscription.isPut && subscription.stockPrice === null && subscription.isValidSymbol && !isTodayHoliday) {
      return;
    }

    if (subscription.isPut && this.putSubscriptionTimeout) {
      clearTimeout(this.putSubscriptionTimeout);
    } else if (this.callSubscriptionTimeout) {
      clearTimeout(this.callSubscriptionTimeout);
    }

    const options = dataToProcess.options.map(({ strike, bid, ask, delta }) => {
      const roi = subscription.isPut
        ? calc.getSellPutsPremiumAnnualarized(
            strike,
            subscription.expiration,
            this.putBuyingPower,
            this.maxPosition,
            bid,
          )
        : subscription.stockPrice
          ? calc.getSellCallsOptionPremiumAnnularized(
              subscription.stockPrice,
              bid,
              this.callNumberOfShares,
              subscription.expiration,
            )
          : null;

      return {
        strike,
        bid,
        ask,
        delta,
        roi: !isNaN(roi) && roi !== null ? Math.round(roi) : null,
      };
    });

    if (subscription.isPut) {
      options.sort((a: IOption, b: IOption) => b.strike - a.strike);
    }

    const optionsChain = {
      ...dataToProcess,
      options,
    } as IOptionsChain;

    if (subscription.isPut) {
      this.putOptionsChain = optionsChain;

      if (this.putOverlayTimeout) {
        clearTimeout(this.putOverlayTimeout);
      }

      this.putOverlayTimeout = setTimeout(() => {
        this.isPutUpdateOverlay = false;
      }, 500);
    } else {
      this.callOptionsChain = optionsChain;

      if (this.callOverlayTimeout) {
        clearTimeout(this.callOverlayTimeout);
      }

      this.callOverlayTimeout = setTimeout(() => {
        this.isCallUpdateOverlay = false;
      }, 500);
    }

    if (isTodayHoliday) {
      this.onBeforeLiveDataStockPriceHandlerCallback(null, isTodayHoliday);
    }
  }

  private calculatePremiums(
    strike: number | string,
    expiration: string,
    isPut: boolean,
  ): Array<{ day: string; dte: number; min_roi: number }> {
    const timeMarker = moment.tz(EasternTimeZoneName).startOf('day');
    const maxDate = convertToEasternTime(expiration);
    const parsedStrike = parseFloat(strike?.toString());

    if (!parsedStrike || isNaN(parsedStrike) || !maxDate?.isValid()) {
      return [];
    }

    const minPremiums = [];

    while (timeMarker.unix() <= maxDate.unix()) {
      if (!this.marketTimeService.isHoliday(timeMarker) && !this.marketTimeService.isWeekend(timeMarker)) {
        const minRoi = isPut
          ? calc.getSellPutsMinOptionPremium(strike, this.buyingPower, this.maxPosition, expiration, timeMarker)
          : calc.getSellCallsMinOptionPremium(strike, expiration, timeMarker);

        const dte = isPut
          ? calc.getSellPutsDTE(expiration, timeMarker.clone())
          : calc.getSellCallsDTE(expiration, timeMarker.clone());

        minPremiums.push({
          day: timeMarker.format('ddd, MMM D'),
          dte,
          min_roi: formatDecimal(minRoi),
        });
      }

      timeMarker.add(1, 'day');
    }

    return minPremiums;
  }
}
